import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, Platform } from "react-native";
//import WebView from "react-native-webview"; //NOT USED
//import HTML from "react-native-render-html"; //NOT USED
import moment from 'moment-timezone';


import Screen from '../components/Screen';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import useAuth from "../auth/useAuth";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";
import alert from '../components/alert';

import { ErrorMessage, ErrorMessage2 } from "../components/forms";
import Switch from "../components/forms/Switch";

function AppSettingsScreen({ route, navigation }){
    const [timeZone, setTimezone] = useState();
    const auth = useAuth();
    const getAppSettings = useApi(keepConnectApi.getAppSettings);
    const setAppSettings = useApi(keepConnectApi.setAppSettings);
    const [requestFailed, setRequestFailed] = useState(false);   
    const [isLoading, setIsLoading] = useState(true);
    const [dropArray, setDropArray] = useState([]);
    const [enterprise, setEnterprise] = useState(false);
    const [enableDiscrete, setEnableDiscrete] = useState(false);
    const [allServerSettings, setAllServerSettings] = useState({});



    useEffect(() => {
        loadKeepConnects();
    }, []);

   const loadKeepConnects = async () => {
    //setIsLoading(true);
    // const storedLicense = await keepConnectStore.get("license");
    // const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
    setEnterprise(auth?.userPermissions?.license == "enterprise");
    const storedTimezone = await keepConnectStore.get("kc_appTimezone");
    const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : null);
    //console.log("Stored Response:");
    //console.log(storedTimezone);
    const response = await getAppSettings.request();
    //console.log("API Response");
    //console.log(response.data.kc_appTimezone);
    if (!response.ok){
        setRequestFailed(true);
        if (correctedStoredTimezone !==null) setTimezone(correctedStoredTimezone);
        return setIsLoading(false);
    } else setRequestFailed(false);
    if(response.data){
        setAllServerSettings(response.data);
    }
    if(response.data && response.data.kc_appTimezone){
        const serverTimeZone = response.data.kc_appTimezone;
        setTimezone(serverTimeZone);
        if(serverTimeZone !== correctedStoredTimezone){
            await keepConnectStore.store("kc_appTimezone", serverTimeZone);
        }
    } else if (correctedStoredTimezone !==null) setTimezone(correctedStoredTimezone);
    
    let jsonObj = [];
    (moment.tz.names()).forEach(function(key){
        jsonObj.push({
            label: key,
            value: key
        });
    });
    setDropArray(jsonObj);
    setIsLoading(false);

    };

    const warnOfDiscreteEnable = (state) => {
        if(state === false){
            setAllServerSettings({...allServerSettings, enableDiscrete: state});
            return;
        }
        alert(
            "Enable Rebooter Discrete On/Off Control?\n(Disabled by default)",
            "This will enable you to set a rebooter into an on or off state. This is NOT a Power Cycle Event. This will turn the socket off or on and it will remain that way until you change it again. This could result in loss of communication if a router/modem is connected. To cycle power off/on, please use the Power Cycle feature instead.",
            [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "Continue", onPress: () => {
                    setAllServerSettings({...allServerSettings, enableDiscrete: state});
                } }
              ]
          );
    }
   
    const handleSave = async () => {
        setIsLoading(true);
        const response = await setAppSettings.request({...allServerSettings});
        //console.log(response);
        await keepConnectStore.store("kc_appTimezone", allServerSettings.kc_appTimezone);
        await keepConnectStore.store("kc_appsettings", allServerSettings);
        if (!response.ok || response.data.MSG !== "Success!"){
            setRequestFailed(true);
            return setIsLoading(false);
        } else setRequestFailed(false);
        setIsLoading(false);
    }

    
    
    return(
    <Screen>
        <Text style={{paddingTop: 20, fontWeight: "bold", alignSelf: "center"}}>App Settings</Text>
        {!isLoading ? 
        <>
        <ErrorMessage
        error="Unable Communicate With Server"
        visible={requestFailed}
        />
        <Dropdown
          labelText={"Select Timezone"}
          defaultValueProp={allServerSettings?.kc_appTimezone}
          itemsArray= {dropArray}
          onChangeItemProp={(value) => setAllServerSettings({...allServerSettings, kc_appTimezone: value})}
        />
        <Switch 
            labelText='Enable Discrete ON/OFF Control'
            switchState={allServerSettings?.enableDiscrete}
            setSwitchState={(value) => warnOfDiscreteEnable(value)} 
            isDisabled = {false}
        />
        <Switch 
            labelText='Enable Uncalibrated Power Measurements'
            switchState={allServerSettings?.enableUncalibratedPower}
            setSwitchState={(value) => setAllServerSettings({...allServerSettings, enableUncalibratedPower: value})} 
            isDisabled = {false}
        />
        <Button
            title="Save Settings"
            onPress={() => handleSave()}
        />
        {enterprise && auth.userPermissions.role === 'admin' && <Button
            title="Manage Users"
            onPress={()=>{navigation.navigate(routes.USERMANAGEMENT);}}
        />}
        </>
        :
        <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View>}
    </Screen>
);
    }

export default AppSettingsScreen;

/*
text += "<b>Remote Reboot: </b>" + (data[0].remoteReboot == 1 ? "Staged" : "Not Staged") + "<br>";
text += "<b>Remote Settings Change: </b>" + (data[0].remoteSettingsChange == 1 ? "Staged" : "Not Staged") + "<br>";
*/