import React, { createContext, useContext, useEffect, useState } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import useAuth from "../auth/useAuth";
import init from 'react_native_mqtt';

init({
  size: 10000,
  storageBackend: AsyncStorage,
  defaultExpires: 1000 * 3600 * 24,
  enableCache: true,
  reconnect: true,
  sync: {}
});

const MQTTContext = createContext(null);

export const useMQTT = () => useContext(MQTTContext);

const MQTTProvider = ({ children }) => {
  const auth = useAuth();
  const [client, setClient] = useState(null);
  const [messages, setMessages] = useState([]);
  const [isConnectedMQTT, setIsConnected] = useState(false);

  useEffect(() => {
    if (!auth.mqttParams.url) return;

    const connectClient = () => {
      const newClient = new Paho.MQTT.Client(
        auth.mqttParams.url,
        61619,
        '',
        'clientId-' + Math.random().toString(16).substr(2, 8)
      );

      const onConnect = () => {
        console.log('Connected to MQTT broker');
        setIsConnected(true);
      };

      const onConnectionLost = (responseObject) => {
        if (responseObject.errorCode !== 0) {
          console.log('Connection lost:', responseObject.errorMessage);
          setIsConnected(false);
          // Attempt to reconnect
          setTimeout(connectClient, 5000); // Try to reconnect after 5 seconds
        }
      };

      const onMessageArrived = (message) => {
        console.log('Message arrived:', message.payloadString);
        const parsedMessage = JSON.parse(message.payloadString);
        setMessages((prevMessages) => [...prevMessages, parsedMessage]);
      };

      newClient.onConnectionLost = onConnectionLost;
      newClient.onMessageArrived = onMessageArrived;

      newClient.connect({
        onSuccess: onConnect,
        userName: auth.mqttParams.username,
        password: auth.mqttParams.password,
        useSSL: true,
        onFailure: (err) => {
          console.error('Connect failed:', err);
          setIsConnected(false);
          // Attempt to reconnect
          setTimeout(connectClient, 5000); // Try to reconnect after 5 seconds
        },
      });

      setClient(newClient);
    };

    connectClient();

    return () => {
      if (client && client.isConnected()) {
        client.disconnect();
      }
    };
  }, [auth.mqttParams]);

  // Function to remove colons from MAC address
  const getMacWithoutColons = (mac) => {
    return mac.replace(/:/g, '');
  };

  const triggerHeartbeat = (mac) => {
    if (client && client.isConnected()) {
      const mqttMessage = new Paho.MQTT.Message("heartbeat");
      mqttMessage.destinationName = `${getMacWithoutColons(mac)}/in`;
      client.send(mqttMessage);
      console.log('Sent MQTT message: heartbeat to', mqttMessage.destinationName);
    } else {
      console.log('MQTT client is not connected');
    }
  };

  return (
    <MQTTContext.Provider
      value={{ client, messages, isConnectedMQTT, triggerHeartbeat }}
    >
      {children}
    </MQTTContext.Provider>
  );
};

export default MQTTProvider;
