import React, { useEffect, useState, useRef } from 'react';
import { Text, View, Image, StyleSheet, Linking, ActivityIndicator, Platform, TextInput, TouchableOpacity} from 'react-native';
//import Modal from 'react-native-modal';
import Modal from '../components/PlatformModal';
import { MaterialCommunityIcons, MaterialIcons } from '../components/PlatformSpecificIcons';
import { useMQTT } from '../components/MQTTContext';
import moment from 'moment-timezone';
import Dropdown from '../components/Dropdown';
import Switch from '../components/forms/Switch';
import PowerSwitch from '../components/forms/PowerSwitch';
import AppTextInput from '../components/TextInputRef';
let NativeModules;
let NativeEventEmitter;

if (!(Platform.OS === 'web' || Platform.OS === 'macos')) {
  NativeModules = require('react-native').NativeModules;
  NativeEventEmitter = require('react-native').NativeEventEmitter;
}
//import { Dropdown } from 'react-native-material-dropdown';
console.log("NativeModules: ", NativeModules);
//import RNPickerSelect from "@react-native-community/picker";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import { SquareButton, ButtonRow, ActionButton, DeleteButton } from '../components/JCButtons';
import routes from "../navigation/routes";
import useAuth from "../auth/useAuth";
import useApi from "../hooks/useApi";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import secureStorage from "../auth/vpnSecureStorage";
import { ErrorMessage, ErrorMessage2 } from "../components/forms";
import colors from '../config/colors';
//import { setEnabled } from 'react-native/Libraries/Performance/Systrace';
//import { createNativeWrapper } from 'react-native-gesture-handler';


function DeviceScreen({ route, navigation }){
    const auth = useAuth();
    const { client, messages, triggerHeartbeat } = useMQTT();
    const [keepConnectsArray, setKeepConnectsArray] = useState([{label: "No Keep Connects Registered", value: "null"}]);
    const [incompatibleKeepConnects, setIncompatibleKeepConnects] = useState([]);
    const [boolIncompatibleKeepConnects, setBoolIncompatibleKeepConnects] = useState(false);
    const [isSubscribed, setSubscription] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshing, setRefreshing] = useState(false);
    const [selectedValue, setSelectedValue]= useState("");
    const [selectedName, setSelectedName]= useState();
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings);
    const triggerKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi);
    const removeKeepConnectApi = useApi(keepConnectApi.removeKeepConnect);
    const renameKeepConnectApi = useApi(keepConnectApi.renameKeepConnect);
    const [defaultKeepConnect, setDefaultKeepConnect] = useState("");
    const [visibleVPN, setVisibleVPN] = useState(false);
    const [selectedTunnelType, setSelectedTunnelType] = useState(0);
    const [selectedConnType, setSelectedConnType] = useState(0);
    const [storeVPN, setStoreVPN] = useState(true);
    const [vpnPassword, setVPNpassword] = useState("");
    const [vpnPort, setVPNport] = useState("4550");
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [visibleTriggerF, setVisibleTriggerF] = useState(false);
    const renameModalRef = useRef();
    const newDeviceNameRef = useRef();
    const [visibleTrigger2, setVisibleTrigger2] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("Error Communicating with Server");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [emptyKeepConnects, setEmptyKeepConnects] = useState(true);
    const [updateNeeded, setUpdateNeeded] = useState(false);
    const [updateNeededHigherLevel, setUpdateNeededHigherLevel] = useState(false);
    const [data, setSettings] = useState();
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2
    const [newSettings5, setNewSettings5] = useState(false); //used for adding new settings with KeepConnectV2
    const [enablePowerPal, setPowerPal] = useState(false);
    const [deviceType, setDeviceType] = useState(0); // 0: Keep Connect, 1: Power Pal, 2: Water Pal, 3: Climate Pal
    const [isConnected, setIsConnected] = useState(false);
    const [appTimezone, setAppTimeZone] = useState("America/New_York");
    const [moreAppSettings, setMoreAppSettings] = useState();
    const [enterpriseOrPro, setEnterpriseOrPro] = useState(false);
    const [vpnEnabled, setVPNenabled] = useState(false);
    const [vpnConnectionType, setVpnConnectionType] = useState("");
    const [thisIsVPN, setThisIsVPN] = useState(false);
    const isDesktop = Platform.OS === 'windows' || Platform.OS === 'macos';
    const [isPowerOn, setIsPowerOn] = useState(false);
    const [awaitingStateChange, setAwaitingStateChange] = useState(false);
    const [intendedPowerState, setIntendedPowerState] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [deviceData, setDeviceData] = useState({});
    const [battEnable, setBattEnable] = useState(false);

    const TIMEOUT_DURATION = 30000; // Timeout duration in milliseconds

    // Function to remove colons from MAC address
    const getMacWithoutColons = (mac) => {
        return mac.replace(/:/g, '');
    };

    const initiatePowerToggle = (state) => {
        alert(
            "Set On/Off",
            "This is NOT a Power Cycle Event. This will turn the socket off or on and it will remain that way until you change it again. This could result in loss of communication if a router/modem is connected. To cycle power off/on, please use the Power Cycle button instead.",
            [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "Proceed", onPress: () => {
                    handlePowerToggle(state);
                } }
              ]
          );
    }

    const handlePowerToggle = (state) => {
        const message = state ? 'on' : 'off';
        //const message = "cycle";
        setIntendedPowerState(state);
        setAwaitingStateChange(true);
        setIsPowerOn(state);
        setButtonDisabled(true);
        //console.log("Mqtt Client: ", client);
        if (client && client.isConnected()) {
            const mqttMessage = new Paho.MQTT.Message(message);
            mqttMessage.destinationName = `${getMacWithoutColons(route.params.item.MAC)}/in`;
            //mqttMessage.destinationName = '543204C216F8/in';
            client.send(mqttMessage);
            console.log('Sent MQTT message:', message);
            // Set a timeout to reset awaitingStateChange
            setTimeout(() => {
                setAwaitingStateChange(false);
                setButtonDisabled(false);
                if(awaitingStateChange && isPowerOn != intendedPowerState){
                    alert(
                        "Error",
                        "Device did not respond to power toggle request.",
                        [
                            { text: "OK", onPress: () => {} }
                        ]
                        );
                }
            }, TIMEOUT_DURATION);
        } else {
          console.log('MQTT client is not connected');
        }
      };

    const handleRequestStatus = () => {
        //const message = state ? 'ON' : 'OFF';
        const message = "status";
        //console.log("Mqtt Client: ", client);
        if (client && client.isConnected()) {
            const mqttMessage = new Paho.MQTT.Message(message);
            mqttMessage.destinationName = `${getMacWithoutColons(route.params.item.MAC)}/in`;
            //mqttMessage.destinationName = '543204C216F8/in';
            client.send(mqttMessage);
            console.log('Sent MQTT message:', message);
        } else {
          console.log('MQTT client is not connected');
        }
      };
    
      useEffect(() => {
        if (client && client.isConnected()) {
          const topic = `${getMacWithoutColons(route.params.item.MAC)}/out`;
          client.subscribe(topic, { qos: 0 });
        }
        const intervalId = setInterval(() => {
          handleRequestStatus();
        }, 40000);
        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
      }, [client]);
    
      useEffect(() => {
        if (messages.length > 0) {
          const latestMessage = messages[messages.length - 1];
          console.log('Received MQTT message:', latestMessage);
          // Handle the message as needed, e.g., updating component state
          if (latestMessage.mac === route.params.item.MAC) {
            setDeviceData(latestMessage);
    
            // Clear awaitingStateChange if the state matches
            if (latestMessage.state) {
                setIsPowerOn(latestMessage.state);
                if(awaitingStateChange && latestMessage.state == intendedPowerState){
                    setAwaitingStateChange(false);
                    setButtonDisabled(false);
                }
            }
            setTimeout(() => {
                if (client && client.isConnected()) {
                    const mqttMessage = new Paho.MQTT.Message("status");
                    mqttMessage.destinationName = `${getMacWithoutColons(route.params.item.MAC)}/in`;
                    //mqttMessage.destinationName = '543204C216F8/in';
                    client.send(mqttMessage);
                    console.log('Sent Status Keep Alive:', "status");
                    // Set a timeout to reset awaitingStateChange
                } else {
                  console.log('MQTT client is not connected');
                }
            }, 40000);
          }
        }
      }, [messages]);

    //console.log("KCVPNInterface", NativeModules.KCVPNInterface);

    const VPN_STATES = {
        Disconnected: 0,
        Connecting: 1,
        Connected: 2,
        Disconnecting: 3,
        Reconnecting: 4,
      };
    const [vpnState, setVPNState] = useState(VPN_STATES.disconnected);

    const [vpnSettings, setVpnSettings] = useState({
        vpnPassword: '',
        selectedTunnelType: 0,
        selectedConnType: 0,
        vpnPort: '4550',
        vpnRoute: '',
        vpnRouteSubnet: '',
        storeVPN: true,
        init: false,
      });


      useEffect(() => {
        loadKeepConnects();
        handleRequestStatus(); //start polling mqtt data
        if(!(Platform.OS === 'web' || isDesktop)){
                console.log("VPN Interface: ", NativeModules.KCVPNInterface);
                const { KCVPNInterface } = NativeModules;
                const handleStatusChange = (response) => {
                    //console.log('VPN status did change:', response.status, "MAC Address:", response.mac);
                    if(response.mac == route.params.item.MAC) {
                        setThisIsVPN(true);
                    } else{
                        setThisIsVPN(false);
                    }
                    const state = VPN_STATES[response.status];
                    if (state !== undefined) {
                    setVPNState(state);
                    } else {
                    //console.log('Unknown status:', response.status);
                    }
                };
            
                KCVPNInterface.getVPNStatus((response) => {
                    //console.log("VPN found status:", response.status, "MAC Address:", response.mac);
                    if(response.mac == route.params.item.MAC) {
                        setThisIsVPN(true);
                    } else{
                        setThisIsVPN(false);
                    }
                    const state = VPN_STATES[response.status];
                    if (state !== undefined) {
                    setVPNState(state);
                    } else {
                    //console.log('Unknown status:', response.status);
                    }
                });
            
                const eventEmitter = new NativeEventEmitter(KCVPNInterface);
            
                // Add the listener and keep a reference to the subscription
                const subscription = eventEmitter.addListener('vpnStatusDidChange', handleStatusChange);
            
                // Cleanup function:
                return () => {
                    // Use the remove method on the subscription object
                    subscription.remove();
                    const topic = `${getMacWithoutColons(route.params.item.MAC)}/out`;
                    client?.unsubscribe(topic);
                };
        }
        
    }, []);

    
    useEffect(() => {
        if(!(Platform.OS === 'web' || isDesktop)){
                const { KCVPNInterface } = NativeModules;
                if (thisIsVPN && vpnState === VPN_STATES.Connected) {
                    if (typeof KCVPNInterface.getVPNConnectionType === 'function') { //check if this exists, previous release didn't include this
                        KCVPNInterface.getVPNConnectionType((connectionType) => {
                            //console.log("connectionType=", connectionType);
                            setVpnConnectionType(connectionType);
                        });
                    } else{
                        setVpnConnectionType("Relay"); //if getVPNConnectionType doesn't exist, then that's before the release where Direct was allowed anyway.
                    }
                } else {
                setVpnConnectionType(""); // reset the value when VPN is not connected
                }
        }
      }, [thisIsVPN, vpnState]);
      

    const loadKeepConnects = async () => {
        //setIsloading(true);
        //setIsLoading(false);
        //console.log(await keepConnectStore.get("keepConnect"));
        keepConnectStore.store("keepConnect", (route.params.item.MAC));
        const response = await getKeepConnectSettingsApi.request("\"" + route.params.item.MAC +"\"");
        const settings = await secureStorage.getSettings(route.params.item.MAC);
        if(settings){
            setVpnSettings(settings); //if vpn settings are stored, initialize them
        }
        setSettings(response.data);
        //console.log(data[0]);
        setVPNenabled(response.data[0].firmware.includes("VPN"));
        setBattEnable(response.data[0].firmware.includes("BATT"));

        const firmwareString = response.data[0].firmware;
        const majorVersionStart = firmwareString.indexOf("_V") + 2;
        const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
        const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);
        setNewSettings1(majorVersion >= 3);
        setNewSettings5(majorVersion >= 5);

        //setPowerPal(response.data[0].firmware.includes("PowerPal"));
        setDeviceType(response.data[0].powerPal ? response.data[0].powerPal : "0");
        setIsPowerOn(JSON.parse(response.data[0].realtimeData)?.state);
        setDeviceData(JSON.parse(response.data[0].realtimeData));
        var then = moment(response.data[0].lastConnectTime, "YYYY-DD-MM HH:mm:ss");
        var now = moment().tz("America/New_York");
        setIsConnected(now.diff(then, 'minutes') <= (response.data[0].powerPal && parseInt(response.data[0].powerPal) > 0 ? (response.data[0].pollTime / 60000 + 2) : 7));

        const storedTimezone = await keepConnectStore.get("kc_appTimezone");
        const correctedStoredTimezone = (storedTimezone !== null ? storedTimezone.replace(/\"/g, "") : "America/New_York");
        setAppTimeZone(correctedStoredTimezone);
        const storedMoreAppSettings = await keepConnectStore.get("kc_appsettings");
        //console.log("Stored App Settings: ", storedMoreAppSettings, JSON.parse(storedMoreAppSettings));
        setMoreAppSettings(JSON.parse(storedMoreAppSettings));
        // const storedLicense = await keepConnectStore.get("license");
        // const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
        setEnterpriseOrPro(auth?.userPermissions?.license == "enterprise" || auth?.userPermissions?.license == "pro");
        checkAndSetFirmwareCompatibility();
        setIsLoading(false);
        setRefreshing(false);
        }

    if (updateNeeded || updateNeededHigherLevel){
        if(Platform.OS === 'web' || isDesktop) setIsLoading(true);
        setRefreshing(true);
        setUpdateNeeded(false);
        if(updateNeededHigherLevel) route.params.changedDevices(true);
        setUpdateNeededHigherLevel(false);
        loadKeepConnects();
    }

    const convertTimezone = (item) =>{
        moment.tz.setDefault("America/New_York");
        var timestamp = moment(item, "YYYY-DD-MM HH:mm:ss");
        return timestamp.tz(appTimezone).format('YYYY-MM-DD HH:mm:ss');
    }

    const checkAndSetFirmwareCompatibility = () => {
        if (route.params.item.firmware.includes("KeepConnect") || route.params.item.firmware.includes("Pal")) setBoolIncompatibleKeepConnects(false);
        else setBoolIncompatibleKeepConnects(true);
    }

    const handleRequestFirmwareUpdate = async () => {
        const keepConnectCodeForEmail = route.params.item.WebID;
        
        alert(
            "Firmware Update",
            "Our Support Staff will stage your device to receive the update. This will cause your device to reboot and will power cycle your router/modem as well. The update will happen within appx 1hr once our staff has staged your device for upgrade. If you require a specific time for your device to be updated, please note this in the email generated by clicking the \"Proceed\" Button.",
            [
                {
                  text: "Cancel",
                  onPress: () => console.log("Cancel Pressed"),
                  style: "cancel"
                },
                { text: "Proceed", onPress: () => {
                    Linking.openURL('mailto: support@johnson-creative.com?subject=Firmware Update Request&body=Please Update Keep Connect Device with WebID ' + keepConnectCodeForEmail);
                } }
              ]
          );
    }

    const handleLearnMoreFirmwareUpdate = ()=>{
        alert(
            "Firmware Update Needed",
            "The selected device requires newer firmware for full compatibility with Keep Connect Cloud Services. The \"Trigger Reset\" and \"Change Settings\" features will not work until the firmware is updated. All other features will work as expected without updating the firmware. Click the \"Request Firmware Update\" option to generate firmware update request email.",
            [
              { text: "OK", onPress: () => {} }
            ]
          );
    }

    /*
    const bwjCrossPlatformAlert = (title, body, buttons)=>{
        console.log("crossPlat");
        if(Platform !== 'web'){
            Alert.alert(title, body, buttons);
        } else{

        }
    }
    */

    const toggleModal = () => {
        setVisibleTrigger(!visibleTrigger);
        };

    const handleTrigger = async (sendIt) => {
        setVisibleTrigger(false);
        if(sendIt){
        const keepConnectCode = route.params.item.MAC;
        const response = await triggerKeepConnectApi.request({keepConnects: keepConnectCode.replace(/\"/g, ""), triggerType: "remoteReboot"});
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
            "Success!",
            "Power Cycle was staged successfully. Your device will read and apply this trigger in appx 5min.",
            [
              { text: "OK", onPress: () => {} }
            ]
          );
        triggerHeartbeat(keepConnectCode);
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        }
        }

    const renameKeepConnect = async () => {
        renameModalRef.current?.closeModal();
        console.log("New Device Name: ", newDeviceNameRef.current);
        route.params.item.Name = newDeviceNameRef.current;
        const response = await renameKeepConnectApi.request(route.params.item.MAC, newDeviceNameRef.current);
        setUpdateNeededHigherLevel(true);
        loadKeepConnects();
    }

    const handleTriggerFirmwareUpdate = async (sendIt) => {
        setVisibleTriggerF(false);
        if(sendIt){
        const keepConnectCode = route.params.item.MAC;
        const response = await triggerKeepConnectApi.request({keepConnects: keepConnectCode.replace(/\"/g, ""), triggerType: "firmware"});
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
            "Success!",
            "The unit will check for latest firmware and update if needed (it may take a few minutes). If an update is available, the device will blink blue led and reboot WITHOUT cycling the output power, and reconnect to internet immediately.",
            [
              { text: "OK", onPress: () => {} }
            ]
          );
        triggerHeartbeat(keepConnectCode);
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        }
        }

    function getNetworkAddress(ip, subnet) {
        let ipArray = ip.split(".");
        let subnetArray = subnet.split(".");
        let networkAddressArray = [];
        
        for(let i = 0; i < 4; i++) {
            networkAddressArray[i] = Number(ipArray[i]) & Number(subnetArray[i]);
        }
        
        return networkAddressArray.join(".");
    }


    const handleVPNsave = async (proceed) => {
        setVisibleVPN(false);
        if(!proceed) return;
        /*
        console.log(vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : "test");
        console.log(vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort);
        console.log(data[0].macAddress);
        console.log(vpnSettings.vpnPassword);
        console.log(getNetworkAddress(data[0].localIP, data[0].localSubnet));
        console.log(data[0].localSubnet);
        console.log(vpnSettings.selectedConnType == 1);
        */
        vpnSettings.init = true;
        if(vpnSettings.storeVPN){ //if allowed, store settings in secure storage
            await secureStorage.storeSettings(route.params.item.MAC, vpnSettings);
        } else{
            await secureStorage.removeSettings(route.params.item.MAC);
        }
    }

    const handleDeregister = async (sendIt) => {
        setVisibleTrigger2(false);
        if(sendIt){
        setIsLoading(true);
        const keepConnectCode = route.params.item.MAC;
        const response = await removeKeepConnectApi.request(keepConnectCode.replace(/\"/g, ""));
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Your Keep Connect was successfully removed!",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        //loadKeepConnects();
        route.params.changedDevices(true);
        navigation.goBack();
        }
        }

    const displayVPNNotice = () => {
        var msg = "";
        if (auth.userPermissions.role !== 'admin'){
            msg = "Not Allowed for Read Only Access.";
        } else if(!enterpriseOrPro){
            msg = "Pro Or Enterprise License Is Required.";
        } else if(!vpnEnabled){
            msg = "Device requires firmware update.";
        } else if(data[0].vpnMode == "0"){
            msg = "Device VPN setting is set to off. Send new settings to the device and refresh this page to clear this error.";
        }
        alert(
            "Error",
            msg,
            [
                { text: "OK", onPress: () => {} }
            ]
            );
    }

    const renderDeviceSpecificInfo = () => {
        switch (deviceType) {
            case "0": //rebooter
                return (
                    <>
                        <Text><Text style={{fontWeight: "bold"}}>Last Reset: </Text><Text>{convertTimezone(data[0].lastResetTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>
                        <Text><Text style={{fontWeight: "bold"}}>Number of Resets: </Text><Text>{data[0].numberOfResets}</Text></Text>
                        <Text><Text style={{fontWeight: "bold"}}>Cloud Notifications: </Text><Text>{data[0].offlineCloudNotificationDelay == 0 ? "Not Enabled" : "Enabled"}</Text></Text>
                        {newSettings5 && isConnected && <>
                        {JSON.parse(data[0].realtimeData)?.U && (
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>Uptime: </Text>
                                <Text>
                                {(() => {
                                    const totalSeconds = deviceData?.uptime || JSON.parse(data[0].realtimeData)?.U;
                                    const days = Math.floor(totalSeconds / (3600 * 24));
                                    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                })()}
                                </Text>
                            </Text>
                            )}
                        {(data[0]?.FactoryCalibrated=== "1" || moreAppSettings?.enableUncalibratedPower) && <><Text><Text style={{ fontWeight: "bold" }}>Power: </Text><Text>{deviceData?.power || JSON.parse(data[0].realtimeData)?.P} W</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Voltage: </Text><Text>{deviceData?.voltage || JSON.parse(data[0].realtimeData)?.V} V</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Amps: </Text><Text>{deviceData?.current || JSON.parse(data[0].realtimeData)?.A} A</Text></Text></>}
                        {moreAppSettings?.enableDiscrete && <PowerSwitch 
                            iconPosition='left'
                            switchState={deviceData?.state === "1"}
                            setSwitchState={initiatePowerToggle} 
                            isDisabled = {buttonDisabled || auth.userPermissions.role !== 'admin'}
                        />}
                        </>}
                    </>
                );
            case "1": //powerpal
                return (
                    <>
                        <Text><Text style={{fontWeight: "bold"}}>Number of Events: </Text><Text>{data[0].numberOfResets}</Text></Text>
                        {newSettings5 && isConnected && <>
                        {JSON.parse(data[0].realtimeData)?.U && (
                        <Text>
                            <Text style={{ fontWeight: "bold" }}>Uptime: </Text>
                            <Text>
                            {(() => {
                                const totalSeconds = deviceData?.uptime || JSON.parse(data[0].realtimeData)?.U;
                                const days = Math.floor(totalSeconds / (3600 * 24));
                                const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
                                const minutes = Math.floor((totalSeconds % 3600) / 60);
                                const seconds = totalSeconds % 60;
                                return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                            })()}
                            </Text>
                        </Text>
                        )}
                        {(data[0]?.FactoryCalibrated === "1" || moreAppSettings?.enableUncalibratedPower) && <><Text><Text style={{ fontWeight: "bold" }}>Power: </Text><Text>{deviceData?.power || JSON.parse(data[0].realtimeData)?.P} W</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Voltage: </Text><Text>{deviceData?.voltage || JSON.parse(data[0].realtimeData)?.V} V</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Amps: </Text><Text>{deviceData?.current || JSON.parse(data[0].realtimeData)?.A} A</Text></Text></>}
                        <PowerSwitch 
                            iconPosition='left'
                            switchState={deviceData?.state === "1"}
                            setSwitchState={initiatePowerToggle} 
                            isDisabled = {buttonDisabled || auth.userPermissions.role !== 'admin'}
                        />
                        </>}
                    </>
                );
            case "2": //Water Pal
                return (
                    <>
                        <Text><Text style={{ fontWeight: "bold" }}>Status: </Text><Text>{deviceData?.water || JSON.parse(data[0].realtimeData)?.W == 1 ? "Water Detected" : "No Water Detected"}</Text></Text>
                        {battEnable && <Text><Text style={{ fontWeight: "bold" }}>Battery: </Text><Text>{deviceData?.battery || JSON.parse(data[0].realtimeData)?.B}%</Text></Text>}
                    </>
                );
            case "3": //Climate Pal
                return (
                    <>
                        <Text><Text style={{ fontWeight: "bold" }}>Temp: </Text><Text>{deviceData?.temperature || JSON.parse(data[0].realtimeData)?.T}</Text></Text>
                        {battEnable && <Text><Text style={{ fontWeight: "bold" }}>Battery: </Text><Text>{deviceData?.battery || JSON.parse(data[0].realtimeData)?.B}%</Text></Text>}
                        <Text><Text style={{ fontWeight: "bold" }}>Humidity: </Text><Text>{deviceData?.humidity || JSON.parse(data[0].realtimeData)?.H}</Text></Text>
                    </>
                );
            case "4": //Computer Pal
                return (
                    <>
                        <Text><Text style={{fontWeight: "bold"}}>Last Reboot: </Text><Text>{convertTimezone(data[0].lastResetTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>
                        {/* <Text><Text style={{fontWeight: "bold"}}>Number of Resets: </Text><Text>{data[0].numberOfResets}</Text></Text> */}
                        <Text><Text style={{fontWeight: "bold"}}>Cloud Notifications: </Text><Text>{data[0].offlineCloudNotificationDelay == 0 ? "Not Enabled" : "Enabled"}</Text></Text>
                        {isConnected && <>
                        {JSON.parse(data[0].realtimeData)?.U && (
                            <Text>
                                <Text style={{ fontWeight: "bold" }}>Uptime: </Text>
                                <Text>
                                {(() => {
                                    const totalSeconds = deviceData?.uptime || JSON.parse(data[0].realtimeData)?.U;
                                    const days = Math.floor(totalSeconds / (3600 * 24));
                                    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
                                    const minutes = Math.floor((totalSeconds % 3600) / 60);
                                    const seconds = totalSeconds % 60;
                                    return `${days.toString().padStart(2, '0')}:${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                                })()}
                                </Text>
                            </Text>
                            )}
                        <><Text><Text style={{ fontWeight: "bold" }}>CPU: </Text><Text>{deviceData?.cpu || JSON.parse(data[0].realtimeData)?.CPU} %</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>RAM: </Text><Text>{deviceData?.ram || JSON.parse(data[0].realtimeData)?.RAM} %</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>HDD: </Text><Text>{deviceData?.hdd || JSON.parse(data[0].realtimeData)?.HDD} %</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Speed Up: </Text><Text>{deviceData?.speedu || JSON.parse(data[0].realtimeData)?.SpeedU} Mbps</Text></Text>
                        <Text><Text style={{ fontWeight: "bold" }}>Speed Down: </Text><Text>{deviceData?.speedd || JSON.parse(data[0].realtimeData)?.SpeedD} Mbps</Text></Text></>
                        </>}
                    </>
                );
            default:
                return null;
        }
    }

    const renderImageSource = () => {
        switch (deviceType) {
            case "0":
                return require('../assets/kcIcon.png');
            case "1":
                return require('../assets/ppIcon.png');
            case "2":
                return require('../assets/wpIcon.png');
            case "3":
                return require('../assets/cpIcon.png');
            case "4":
                return require('../assets/compPal.png');
            default:
                return require('../assets/kcIcon.png');
        }
    }
    

    return(
        <Screen isLoading={refreshing} setUpdateNeeded={setUpdateNeeded}>
        {isLoading ? <View style={{paddingTop: 120}}><ActivityIndicator size="large" color={colors.primary}/></View> :
        <React.Fragment>
        <View style={{padding: 20, borderTopWidth :3, borderTopColor: colors.primary, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <View style={styles.deviceBox}>
        <ErrorMessage
        error={requestMessage}
        visible={requestFailed}
        />
        <ErrorMessage
        error={serverMessage}
        visible={errorFromServer}
        />
        <ErrorMessage2
          error={"Firmware Update Required\nFor Selected Keep Connect"}
          visible={boolIncompatibleKeepConnects}
        />
        {boolIncompatibleKeepConnects ? <Text style={styles.TextStyle} onPress={ ()=> handleLearnMoreFirmwareUpdate() } >Learn More</Text> : null}
        {boolIncompatibleKeepConnects ? <><Text style={styles.TextStyle} onPress={ ()=> handleRequestFirmwareUpdate() } >Request Firmware Update</Text><Text></Text></> : null}
        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Text style={{ fontWeight: "bold" }}>Keep Connect Name: {route.params.item.Name}  </Text>
            <TouchableOpacity onPress={() => renameModalRef.current.openModal()}>
                <MaterialIcons name="drive-file-rename-outline" size={22} color={colors.primary} />
            </TouchableOpacity>
        </View>
        <Text style={{alignSelf: "center"}}>{route.params.id}</Text><Text></Text>
        {Platform.OS === 'web' && <Text> </Text>}
        <View style={{alignItems: "center"}}>{isConnected ? <View style={{width:200, backgroundColor: '#33cc33', borderRadius: 30, justifyContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Online and Connected </Text>
        </View> : 
        <View style={{width:200, backgroundColor: 'red', borderRadius: 30, alignContent: 'center'}}>
        <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Offline </Text>
        </View>}</View>
        {Platform.OS === 'web' && <Text> </Text>}
        <Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Web ID: </Text><Text>{data[0].id}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>IP: </Text><Text>{data[0].IPaddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Mac: </Text><Text>{data[0].macAddress}</Text></Text>
        <Text><Text style={{fontWeight: "bold"}}>Last Connected: </Text><Text>{} </Text><Text>{convertTimezone(data[0].lastConnectTime)} ({moment().tz(appTimezone).format('z')})</Text></Text>
        {renderDeviceSpecificInfo()}
        <Text>{'\n'}</Text>
        <Image
            source={renderImageSource()} // Adjust the path to your image file
            style={styles.imageStyle}
        />
        </View>
        {Platform.OS === 'ios' ? <></> : <Text style={styles.TextStyle} onPress={ ()=> setUpdateNeeded(true) } >Refresh</Text>}
        {deviceType === "0" && <ButtonRow><SquareButton
            title="Trigger Power-Cycle"
            onPress={toggleModal}
            icon="retweet"
            color="red"
            disabled={auth.userPermissions.role !== 'admin'}
        />
        <SquareButton
            title="Schedule Event"
            onPress={() => navigation.navigate(routes.SCHEDULEEVENT, { item: route.params.item})}
            icon="calendar"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />
        <SquareButton
            title="Notification Settings"
            onPress={() => navigation.navigate(routes.MESSAGESETTINGS, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="send"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />
        </ButtonRow>}
        <ButtonRow>
        {deviceType === "0" && <SquareButton
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGS, { item: {...route.params.item, FactoryCalibrated: data[0].FactoryCalibrated}, changedDevices: setUpdateNeededHigherLevel})}
            icon="cogs"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        {deviceType === "1" && <SquareButton
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGSPOWERPAL, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="cogs"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        {deviceType === "2" && <SquareButton
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGSWATERPAL, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="cogs"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        {deviceType === "3" && <SquareButton
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGSCLIMATEPAL, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="cogs"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        {deviceType === "4" && <SquareButton
            title="View/Change Settings"
            onPress={() => navigation.navigate(routes.CHANGESETTINGSCOMPUTERPAL, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="cogs"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        <SquareButton
            title="View Event History"
            onPress={() => navigation.navigate(routes.DASHBOARD, { item: route.params.item, changedDevices: setUpdateNeededHigherLevel})}
            icon="history"
            color={colors.primary}
        />
        <SquareButton
            title="More Device Details"
            onPress={() => navigation.navigate(routes.PRESENTSETTINGS, { item: route.params.item})}
            icon="search-plus"
            color={colors.primary}
        />
        </ButtonRow>
        {newSettings5 && <ButtonRow>
        {deviceType != "4" && deviceType != "5" && <SquareButton
            title="Update Firmware"
            onPress={() => setVisibleTriggerF(true)}
            icon="cloud-upload"
            color={colors.primary}
        />}
        {parseInt(deviceType) >= 4 && <SquareButton
            title="Trigger Reboot"
            onPress={toggleModal}
            icon="retweet"
            color="red"
            disabled={auth.userPermissions.role !== 'admin'}
        />}
        <SquareButton
            title="Deregister Device"
            onPress={() => setVisibleTrigger2(true)}
            icon="trash"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        />
        </ButtonRow>}
        {!newSettings5 && <ButtonRow><ActionButton
            title="Deregister Device"
            onPress={() => setVisibleTrigger2(true)}
            icon="trash"
            color={colors.primary}
            disabled={auth.userPermissions.role !== 'admin'}
        /></ButtonRow>}
        {Platform.OS !== 'web' && !battEnable && <View style={styles.deviceBox}>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>VPN Control</Text>
        {thisIsVPN && vpnState === VPN_STATES.Connecting ? <Text style={{alignSelf: "center"}}>Initiating Connection...</Text> : <></>}
        {thisIsVPN && vpnState === VPN_STATES.Disconnecting ? <Text style={{alignSelf: "center"}}>Disconnecting...</Text> : <></>}
        <View style={{paddingTop: 2, flexDirection: "row"}}>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Start"
            onPress={() => {
                //NativeModules.KCVPNInterface.setVPNParams("vpnserver.johnson-creative.com", "4550", "60:55:F9:25:D8:44", "byron", "192.168.2.0", "255.255.255.0", false);
                if(!vpnEnabled || !enterpriseOrPro || data[0].vpnMode == "0" || auth.userPermissions.role !== 'admin') {displayVPNNotice(); return;}
                if(vpnSettings.init){
                    NativeModules.KCVPNInterface.stopVPN((success) =>{
                    NativeModules.KCVPNInterface.setVPNParams(
                        vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : data[0].IPaddress, //Packetforwarder OR direct IP
                        vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort, //Packetforwarder(4550) OR custom direct port
                        data[0].macAddress, //macAddress
                        vpnSettings.vpnPassword, //password
                        vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : getNetworkAddress(data[0].localIP, data[0].localSubnet), //vpm route = get network address
                        vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : data[0].localSubnet, //route subnet
                        vpnSettings.selectedConnType == 1 //if ==1 then do "direct connect"
                      );
                      setThisIsVPN(true);
                        /*  
                        console.log("Packetforwarder OR direct IP:", vpnSettings.selectedConnType == 0 ? "vpnserver.johnson-creative.com" : data[0].IPaddress);
                        console.log("Packetforwarder(4550) OR custom direct port:", vpnSettings.selectedConnType == 0 ? "4550" : vpnSettings.vpnPort);
                        console.log("macAddress:", data[0].macAddress);
                        console.log("password:", vpnSettings.vpnPassword);
                        console.log("VPN route = get network address:", vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : getNetworkAddress(data[0].localIP, data[0].localSubnet));
                        console.log("Route subnet:", vpnSettings.selectedTunnelType == 1 ? "0.0.0.0" : data[0].localSubnet);
                        console.log("Direct connect (selectedConnType == 1):", vpnSettings.selectedConnType == 1);
                        */
                      NativeModules.KCVPNInterface.startVPN((success) => {
                          //console.log("VPN startup success:", success);
                          if(!success){
                            alert(
                                "VPN Config Failed",
                                "The selected device failed to install the VPN profile.",
                                [
                                  { text: "OK", onPress: () => {} }
                                ]
                              );
                          }
                        });
                    })
                    } else{
                        setVisibleVPN(true);
                    }
            }}
        />
        </View>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Stop"
            onPress={() => {
                if(!vpnEnabled || !enterpriseOrPro) {displayVPNNotice(); return;}
                NativeModules.KCVPNInterface.stopVPN(()=>{});
            }
            }
        />
        </View>
        </View>
        <View style={{paddingTop: 2, flexDirection: "row", alignItems: 'center'}}>
        <View style={{flex: 1, marginRight: 5}}>
        <Button
            title="Set"
            onPress={() => {
                if(!vpnEnabled || !enterpriseOrPro || auth.userPermissions.role !== 'admin') {displayVPNNotice(); return;}
                setVisibleVPN(true);
            }}
        />
        </View>
        <View style={{flex: 1, marginRight: 5, marginLeft: 25}}>
            {thisIsVPN && vpnState === VPN_STATES.Connected ? <View style={{width:100, backgroundColor: '#33cc33', borderRadius: 30, justifyContent: 'center'}}>
            <Text style={{marginTop: 5, marginBottom: 0, marginLeft: 10, marginRight: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}>Connected</Text>
            <Text style={{marginTop: 0, marginBottom: 5, marginLeft: 10, marginRight: 10, color: "white", alignSelf: "center"}}>({vpnConnectionType})</Text>
            </View> : 
            <View style={{width:100, backgroundColor: 'gray', borderRadius: 30, alignContent: 'center'}}>
            <Text style={{margin: 10, color: "white", alignSelf: "center", fontWeight: "bold"}}> Off </Text>
            </View>}
        </View>
        </View>
        </View>}
        <Modal isVisible={visibleVPN} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleVPNsave(false)}>
        <View style={{height: 700,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{fontWeight: "bold", alignSelf: "center"}}>Enter VPN Settings {'\n'}</Text>
            <Text style={{alignSelf: "center"}}>Enter Device Password {'\n'}</Text>
            <TextInput
                secureTextEntry={true}
                style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                placeholder="Enter your password"
                onChangeText={text => setVpnSettings(prevState => ({ ...prevState, vpnPassword: text }))}
                value={vpnSettings.vpnPassword}
            />
            <Text style={{alignSelf: "center"}}>Note: This is the password entered into the device during setup. It remains local to the device and is not related to your Cloud Services account password. {'\n'}</Text>
            <Dropdown
                onTop={true}
                labelText='Tunnel Type'
                defaultValueProp={vpnSettings.selectedTunnelType}
                itemsArray= {[
                    {label: 'LAN Traffic Only', value: 0},
                    {label: 'All Traffic', value: 1 },
                ]}
                onChangeItemProp={item => setVpnSettings(prevState => ({ ...prevState, selectedTunnelType: item }))}
            />
            <Dropdown
                labelText='Connection Method'
                defaultValueProp={vpnSettings.selectedConnType}
                itemsArray= {[
                    {label: 'Auto (Relay)', value: 0},
                    {label: 'Direct (Port Forward)', value: 1 },
                ]}
                onChangeItemProp={item => setVpnSettings(prevState => ({ ...prevState, selectedConnType: item }))}
            />
            {vpnSettings.selectedConnType === 1 && <TextInput
                keyboardType="numeric"
                style={{ height: 40, borderColor: 'gray', borderWidth: 1 }}
                placeholder="Enter Device Port"
                onChangeText={text => setVpnSettings(prevState => ({ ...prevState, vpnPort: text }))}
                value={vpnSettings.vpnPort}
            />}
            <Switch 
                labelText='Store VPN Settings'
                switchState={vpnSettings.storeVPN}
                setSwitchState={(value) => setVpnSettings(prevState => ({ ...prevState, storeVPN: value }))}
                isDisabled = {false}
            />
            <Button title="Save" onPress={()=>handleVPNsave(true)} />
            <Button title="Cancel" onPress={()=>handleVPNsave(false)} />
            </View>
        </View>
        </Modal>
        <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleTrigger(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure you want to power cycle:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleTrigger(true)} />
            <Button title="Cancel" onPress={()=>handleTrigger(false)} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTriggerF} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleTriggerFirmwareUpdate(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Check and Update Firmare for:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleTriggerFirmwareUpdate(true)} />
            <Button title="Cancel" onPress={()=>handleTriggerFirmwareUpdate(false)} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>handleDeregister(false)}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>Are you sure you want to deregister:{'\n'}{route.params.item.Name}</Text>
            <Button title="Proceed" onPress={()=>handleDeregister(true)} />
            <Button title="Cancel" onPress={()=>handleDeregister(false)} />
            </View>
            </View>
        </Modal>
        <Modal ref={renameModalRef} transparent={true} animationType="slide" onBackdropPress={() => renameModalRef.current.closeModal()}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Enter New Device Name:</Text>
                        <AppTextInput 
                            inputRef={newDeviceNameRef}
                            placeholder="Device Name"
                            style={styles.modalInput}
                        />
                        <Button title="Rename" onPress={renameKeepConnect} />
                        <Button title="Cancel" onPress={() => renameModalRef.current.closeModal()} />
                    </View>
                </View>
            </Modal>
        </View>
        </React.Fragment>}
    </Screen>
);
    }

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
    },
    buttonsContainer: {
        width: "100%",
    },
    logo: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    logoContainer: {
        position: "absolute",
        top: -20,
        alignItems: "center",
        width: "100%",
    },
    tagline: {
        fontSize: 25,
        fontWeight: "600",
        paddingVertical: 20,
    },
    TextStyle: {
         color: colors.primary,
         alignSelf: "center",
      },
    deviceBox: {
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#4eb9d6',
        padding: 5,
    },
    imageStyle: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 80,
        height: 25,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        padding: 22,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 20,
        borderColor: 'rgba(0, 0, 0, 0.1)',
        maxHeight: '80%', // Limit the height of the modal
    },
    modalInput: {
        borderWidth: 1,
        borderColor: colors.light,
        borderRadius: 4,
        padding: 10,
        marginBottom: 10,
        width: '100%',
    },
    // button: {
    //     padding: 10,
    //     margin: 10,
    //     borderWidth: 1,
    //     borderColor: 'black',
    //     borderRadius: 5,
    //     alignItems: 'center',
    //     justifyContent: 'center',
    //     backgroundColor: '#f0f0f0',
    //   },
    //   buttonDisabled: {
    //     backgroundColor: '#d3d3d3',
    //   },
    });

export default DeviceScreen;