import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Colors from '../config/colors';

// SquareButton Component
const SquareButton = ({ icon, title, onPress, color, disabled = false }) => {
  return (
    <TouchableOpacity 
      style={[styles.squareButton, { backgroundColor: color }, disabled && styles.disabledButton]} 
      onPress={onPress} 
      disabled={disabled}
    >
      <FontAwesome name={icon} size={24} color="white" />
      <Text style={styles.squareButtonText}>{title}</Text>
    </TouchableOpacity>
  );
};

// ActionButton Component
const ActionButton = ({ icon, title, onPress, color = Colors.light.primary, disabled = false }) => {
  return (
    <TouchableOpacity 
      style={[styles.actionButton, { backgroundColor: color }, disabled && styles.disabledButton]} 
      onPress={onPress} 
      disabled={disabled}
    >
      {icon && <FontAwesome name={icon} size={20} color="white" />}
      <Text style={styles.buttonText}>{title}</Text>
    </TouchableOpacity>
  );
};

// DeleteButton Component
const DeleteButton = ({ onPress, title = "Delete Project", disabled = false }) => {
  return (
    <TouchableOpacity 
      style={[styles.actionButton, styles.deleteButton, disabled && styles.disabledButton]} 
      onPress={onPress} 
      disabled={disabled}
    >
      <FontAwesome name="trash" size={20} color="white" />
      <Text style={styles.buttonText}>{title}</Text>
    </TouchableOpacity>
  );
};

// LogoutButton Component
const LogoutButton = ({ onPress, disabled = false }) => {
  return (
    <TouchableOpacity 
      style={[styles.actionButton, styles.logoutButton, disabled && styles.disabledButton]} 
      onPress={onPress} 
      disabled={disabled}
    >
      <FontAwesome name="sign-out" size={20} color="white" />
      <Text style={styles.buttonText}>Log Out</Text>
    </TouchableOpacity>
  );
};

// ButtonRow Component
const ButtonRow = ({ children }) => {
  return (
    <View style={styles.buttonRow}>
      {children}
    </View>
  );
};

const styles = StyleSheet.create({
  squareButton: {
    flex: 1, // Allow the button to grow and shrink
    aspectRatio: 1, // Keep it square
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    margin: 5, // Reduced margin
    maxWidth: 100, // Optional: set a max width
  },
  squareButtonText: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 4,
    textAlign: 'center',
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    borderRadius: 8,
    marginBottom: 16,
  },
  deleteButton: {
    backgroundColor: 'red',
  },
  logoutButton: {
    backgroundColor: 'red',
  },
  buttonText: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
    marginLeft: 8, 
  },
  disabledButton: {
    backgroundColor: '#ccc',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: 16,
  },
});

export { ActionButton, DeleteButton, LogoutButton, SquareButton, ButtonRow };
