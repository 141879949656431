import React from "react";
import { View, Switch } from "react-native";
import colors from "../../config/colors";
import { MaterialCommunityIcons } from '../PlatformSpecificIcons';

function AppSwitch({ iconPosition = "left", switchState, setSwitchState, isDisabled }) {
  return (
    <View style={{ flexDirection: "row", alignItems: "center", paddingTop: 10, paddingBottom: 10 }}>
      {iconPosition === "left" && (
        <MaterialCommunityIcons name="power" size={24} color={!switchState ? "red" : "#33cc33"} style={{ marginRight: 10 }} />
      )}
      <Switch 
        trackColor={{ false: "#767577", true: colors.primary }}
        thumbColor={switchState ? "#fafafa" : colors.primary}
        onValueChange={() => setSwitchState(!switchState)}
        value={switchState}
        disabled={isDisabled}
      />
      {iconPosition === "right" && (
        <MaterialCommunityIcons name="power" size={24} color={colors.primary} style={{ marginLeft: 10 }} />
      )}
    </View>
  );
}

export default AppSwitch;
