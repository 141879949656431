import React, { useState, useEffect } from "react";
import { StyleSheet, Image, View, Text, ActivityIndicator, Platform } from "react-native";
//import Modal from 'react-native-modal';
import Modal from '../components/PlatformModal';
import * as Yup from "yup";
import { FileSystem, Sharing, DocumentPicker } from '../components/PlatformFilesystem';

import {
  ErrorMessage,
  Form,
  FormField,
  SubmitButton,
  LabelFormField,
  LabelFormFieldWithoutFormik,
} from "../components/forms";

import Screen from '../components/Screen';
import alert from '../components/alert';
import Button from '../components/Button';
import Dropdown from '../components/Dropdown';
import Switch from '../components/forms/Switch';
import routes from "../navigation/routes";
import useApi from "../hooks/useApi";
import useAuth from "../auth/useAuth";
import keepConnectApi from "../api/keepConnectsApi";
import keepConnectStore from '../auth/keepConnectStorage';
import colors from "../config/colors";
import { useMQTT } from '../components/MQTTContext';
import { set } from "react-native-reanimated";
//import { setStatusBarHidden } from "expo-status-bar";


function ChangeSettingsScreenClimatePal({ route, navigation }){
  const auth = useAuth();
    const getKeepConnectSettingsApi = useApi(keepConnectApi.getKeepConnectSettings);
    const changeSettingsKeepConnectApi = useApi(keepConnectApi.triggersKeepConnectApi); 
    const { triggerHeartbeat } = useMQTT();
    const [data, setExistingSettings] = useState(); 
    const [newSettings1, setNewSettings1] = useState(false); //used for adding new settings with KeepConnectV2 
    const [newSettings2, setNewSettings2] = useState(false); //used for adding new settings with KeepConnectV3
    const [newSettings5, setNewSettings5] = useState(false); //used for adding new settings with KeepConnectV5
    const [isLoading, setIsLoading] = useState(false);
    const [requestFailed, setRequestFailed] = useState(false);
    const [requestMessage, setRequestMessage] = useState("");
    const [errorFromServer, setErrorFromServer] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [selectedNotifications, setSelectedNotifications] = useState("noNotif");
    const [enableAdvanced, setEnableAdvanced] = useState(false);
    const [enableStaticIP, setEnableStaticIP] = useState(false);
    const [enableMsg1, setEnableMsg1] = useState(false);
    const [enableMsg2, setEnableMsg2] = useState(false);
    const [eMSG3, setEnableMsg3] = useState(false);
    const [eMSG4, setEnableMsg4] = useState(false);
    const [enableMsg1More, setEnableMsg1More] = useState(false);
    const [enableMsg2More, setEnableMsg2More] = useState(false);
    const [enableMsg3More, setEnableMsg3More] = useState(false);
    const [enableMsg4More, setEnableMsg4More] = useState(false);
    const [reduceTxPower, setReduceTxPower] = useState(false);
    const [enableCustomSSID, setEnableCustomSSID] = useState(false);
    const [wifiResetMode, setSelectedWiFiResetMode] = useState("1");
    const [wifi802_11Mode, setSelected802_11Mode] = useState("3");
    const [enableWebPortal, setEnableWebPortal] = useState(true);
    const [connectionMonitorMode, setConnectionMonitorMode] = useState("Fullstack");
    const [keepConnectCode, setKeepConnectCode] = useState("");
    const [stagedSettings, setStagedSettings] = useState({});
    const [visibleTrigger, setVisibleTrigger] = useState(false);
    const [visibleTrigger2, setVisibleTrigger2] = useState(true);
    const [visibleTrigger3, setVisibleTrigger3] = useState(false);
    const [defaultPhoneNumber, setDefaultPhoneNumber] = useState("XXXXXXXXXX");
    const [defaultCountryCode, setDefaultCountryCode] = useState("1");
    const [defaultEmailAddress, setDefaultEmailAddress] = useState("");
    const [defaultPollTime, setDefaultPollTime] = useState("1");
    const [defaultOffline, setDefaultOffline] = useState("6");
    const [defaultOnMsg, setDefaultOnMsg] = useState("Power Pal: Connection Restored");
    const [defaultOffMsg, setDefaultOffMsg] = useState("Power Pal: Connection Offline");
    const [defaultLocalIP, setDefaultLocalIP] = useState("");
    const [defaultLocalSubnet, setDefaultLocalSubnet] = useState("");
    const [defaultLocalGateway, setDefaultLocalGateway] = useState("");
    const [defaultLocalDNS1, setDefaultLocalDNS1] = useState("");
    const [defaultLocalDNS2, setDefaultLocalDNS2] = useState("");
    const [defaultLocalSSID, setDefaultLocalSSID] = useState("");
    const [vpnEnabled, setVPNenabled] = useState(false);
    const [enterpriseOrPro, setEnterpriseOrPro] = useState(false);
    const [vpnPort, setVPNport] = useState("4550");
    const [vpnMode, setVPNmode] = useState("0");
    const [saveForExport, setSaveForExport] = useState(false);
    const [delay2, setDelay2] = useState("0");
    const [count1, setCount1] = useState("0");
    const [count2, setCount2] = useState("0");
    const [interval1, setInterval1] = useState("0");
    const [interval2, setInterval2] = useState("0");
    const [dropout1, setDropout1] = useState("60");
    const [dropout2, setDropout2] = useState("60");
    const [MSG3, setMsg3] = useState(false);
    const [MSG4, setMsg4] = useState(false);
    const [GuestMode, setGuestMode] = useState(false);
    const [powerCal, setPowerCal] = useState("1");
    const [AdditionalEmails, setAdditionalEmails] = useState("");
    const [enableAdditionalEmails, setEnableAdditionalEmails] = useState(false);
    const [enableWebhook, setEnableWebhook] = useState(false);
    const [webhookURL, setWebhookURL] = useState("");
    const [webhookKey, setWebhookKey] = useState("text");
    const [enableMQTT, setEnableMQTT] = useState(false);
    const [mqttHost, setMqttHost] = useState('');
    const [mqttClient, setMqttClient] = useState('');
    const [mqttUser, setMqttUser] = useState('');
    const [mqttPassword, setMqttPassword] = useState('');
    const [mqttPort, setMqttPort] = useState(1883);
    const [messages, setMessages] = useState([{
      id: 1, // sequential id starting from 1
      eMSG: true,
      MSG: 'Climate Pal: High Temperature',
      thresh: '100',
      delay: '0',
      count: '1',
      interval: '0',
      dropout: '60',
      enableMoreSettings: false,
      direction: '1',
      action: 'none',
      value: 'Temp'  // Default value for the new dropdown field
    }]);
    const [battEnable, setBattEnable] = useState(false);


    const validationSchema = Yup.object().shape({
      email: Yup.string().email().label("Email"),
      phoneNumber: Yup.string().required().label("Phone Number"), 
      countryCode: Yup.string().required().max(3).label("Country Code"), 
      pollPeriod: Yup.string().required().min(1).label("Poll Period"), 
      offline: Yup.number().required().label("Offline"), 
      onMsg: Yup.string().required().max(98).label("onMsg"), 
      offMsg: Yup.string().required().max(98).label("offMsg"), 
      ip: Yup.string().when('enableIP', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter IP Address")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid IP Address").label("Local IP Address"), //.when('eStaticIP', {is: true, then: Yup.string().required("Must enter IP Address")})
      sm: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Subnet Mask")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Subnet Mask").label("Local Subnet Mask"), 
      gw: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter Gateway IP")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid Gateway IP").label("Local Gateway"), 
      dns1: Yup.string().when('ip', {is: ()=> enableStaticIP, then: Yup.string().required("Must enter DNS Server 1")}).matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS1 IP").label("Local DNS1"), 
      dns2: Yup.string().matches(/^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/,"Invalid DNS2 IP ").label("Local DNS2"), 
      SSID: Yup.string().max(20).label("Custom WiFi SSID"),    
      vpnP: Yup.number().required().min(0).max(65535).label("VPN Port"),   
    });
    

    useEffect(() => {
         loadKeepConnects();
     }, []);

    const loadKeepConnects = async () => {
      const keepConnectCodeTemp = await keepConnectStore.get("keepConnect");
      setKeepConnectCode(keepConnectCodeTemp.replace(/\"/g, "")); //found this function to remove the double quotes
      // const storedLicense = await keepConnectStore.get("license");
      // const correctedStoredLicense = (storedLicense !== null ? storedLicense.replace(/\"/g, "") : "basic");
      setEnterpriseOrPro(auth?.userPermissions?.license == "enterprise" || auth?.userPermissions?.license == "pro");
      }

    const toggleModal = () => {
      setVisibleTrigger(!visibleTrigger);
      };

    const cancelSend = ()=> {
      setStagedSettings({});
      toggleModal();
    }

    const initiateSubmit = async ({ email, phoneNumber, countryCode, pollPeriod, offline, onMsg, offMsg, ssid, ip, sm, gw, dns1, dns2, vpnP, delay2, count1, count2, interval1, interval2, dropout1, dropout2, AdditionalEmails, powerCal, mqttHost, mqttClient, mqttUser, mqttPassword, mqttPort, webhookURL, webhookKey}) => {
      //convert messages array of objects to json
      let formattedMessages = {};
      messages.forEach((message, index) => {
        formattedMessages[`MSG${index + 1}`] = {
          MSG: message.MSG,
          count: message.count,
          delay: message.delay,
          interval: message.interval,
          dropout: message.dropout,
          action: message.action,
          thresh: message.thresh,
          direction: message.direction
        };
      });

      let formattedMessagesJson = JSON.stringify(formattedMessages);

      // Create offline and online JSON structures
      let KCCS_Meta = {
        "online": {
          "count": count2,
          "interval": interval2,
          "dropout": dropout2,
        },
        "offline": {
          "count": count1,
          "interval": interval1,
          "dropout": dropout1,
        }
      };

      let settingsTemp = { 
                notif: selectedNotifications, 
                eadd: email, 
                pn: phoneNumber, 
                cc: countryCode, 
                pollt: pollPeriod,
                offline: offline,
                onMsg : onMsg,
                offMsg : offMsg, 
                webPortal : (enableWebPortal ? 0:82), //82 is the disable code within device firmware
                SSID : (enableCustomSSID ? ssid : ""),
                enableStaticIP : (enableStaticIP ? 82:0),
                ip : ip,
                sm : sm,
                gw : gw,
                dns1 : dns1,
                dns2 : dns2,
                mfc : 2, //set to 2 to ensure KeepLink is always turned off for PowerPal
                tx : (reduceTxPower ? 1:0),
                wr : wifiResetMode,
                wm : wifi802_11Mode,
                vpnMode : vpnMode,
                vpnPort : vpnP,
                keepConnects: keepConnectCode, 
                triggerType: "remoteSettingsChange",
                powerPal : 1,
                delay2: delay2, 
                GuestMode: GuestMode,
                AdditionalEmails: AdditionalEmails,
                Units: units,
                mqttEnable: enableMQTT,
                mqttHost: mqttHost,
                mqttClient: mqttClient,
                mqttUser: mqttUser,
                mqttPassword: mqttPassword,
                mqttPort: mqttPort,
                webhookURL: webhookURL,
                webhookKey: webhookKey,
                detections: formattedMessagesJson,
                KCCS_Meta: JSON.stringify(KCCS_Meta),
              };
        //console.log(settingsTemp);
        setStagedSettings(settingsTemp);
        if(saveForExport){
          saveSettingsToFile(settingsTemp);
          return;
        }
        toggleModal();
        };

    // Function to load settings from a file
    async function loadSettingsFromFile() {
      // Use the document picker to choose a file
      const file = await DocumentPicker.getDocumentAsync({});
    
      // If a file was selected
      if (file.type === 'success') {
        setIsLoading(true);
        let json;
        let settings;
        if (Platform.OS === 'web') {
          // Handle web platform
          const response = await fetch(file.uri);
          const blob = await response.blob();
    
          // Wrap the FileReader logic in a Promise
          const readFile = new Promise((resolve, reject) => {
            const reader = new FileReader();
    
            reader.onload = (event) => {
              json = event.target.result;
              settings = JSON.parse(json);
              resolve(); // Resolve the Promise when done
            };
    
            reader.onerror = reject; // Reject the Promise on error
    
            reader.readAsText(blob);
          });
    
          // Wait for the Promise to resolve before continuing
          await readFile;
        } else {
          // Handle native platform
          json = await FileSystem.readAsStringAsync(file.uri);
          
          // Parse the JSON
          settings = JSON.parse(json);
          // ... rest of your code remains the same ...
        }

        //console.log(settings);
    
        if (settings.notif !== undefined) setSelectedNotifications(settings.notif);
        if (settings.eadd !== undefined) setDefaultEmailAddress(settings.eadd);
        if (settings.pn !== undefined) setDefaultPhoneNumber(settings.pn);
        if (settings.cc !== undefined) setDefaultCountryCode(settings.cc);
        if (settings.pollt !== undefined) setDefaultPollTime(settings.pollt);
        if (settings.offline !== undefined) setDefaultOffline(settings.offline);
        if (settings.onMsg !== undefined) setDefaultOnMsg(settings.onMsg);
        if (settings.offMsg !== undefined) setDefaultOffMsg(settings.offMsg);
        if (settings.webPortal !== undefined) setEnableWebPortal(settings.webPortal === 0);
        if (settings.SSID !== undefined){
          setEnableCustomSSID(settings.SSID !== "");
          if(settings.SSID !== "") setDefaultLocalSSID(settings.SSID);
        }
        if (settings.enableStaticIP !== undefined) setEnableStaticIP(settings.enableStaticIP === 82);
        if (settings.ip !== undefined) setDefaultLocalIP(settings.ip);
        if (settings.sm !== undefined) setDefaultLocalSubnet(settings.sm);
        if (settings.gw !== undefined) setDefaultLocalGateway(settings.gw);
        if (settings.dns1 !== undefined) setDefaultLocalDNS1(settings.dns1);
        if (settings.dns2 !== undefined) setDefaultLocalDNS2(settings.dns2);
        if (settings.tx !== undefined) setReduceTxPower(settings.tx === 1);
        if (settings.wr !== undefined) setSelectedWiFiResetMode(settings.wr);
        if (settings.wm !== undefined) setSelected802_11Mode(settings.wm);
        if (settings.vpnMode !== undefined) setVPNmode(settings.vpnMode);
        if (settings.vpnPort !== undefined) setVPNport(settings.vpnPort);
        //if (settings.keepConnects !== undefined) setKeepConnectCode(settings.keepConnects);
        if (settings.delay2 !== undefined) setDelay2(settings.delay2);
        if (settings.KCCS_Meta !== undefined) {
          const KCCS_Meta = JSON.parse(settings.KCCS_Meta);
          setCount1(KCCS_Meta.offline.count);
          setCount2(KCCS_Meta.online.count);
          setInterval1(KCCS_Meta.offline.interval);
          setInterval2(KCCS_Meta.online.interval);
          setDropout1(KCCS_Meta.offline.dropout);
          setDropout2(KCCS_Meta.online.dropout);
        }
        if (settings.eMSG3 !== undefined) setEnableMsg3(settings.eMSG3);
        if (settings.eMSG4 !== undefined) setEnableMsg4(settings.eMSG4);
        if (settings.MSG3 !== undefined) setMsg3(settings.MSG3);
        if (settings.MSG4 !== undefined) setMsg4(settings.MSG4);
        if (settings.GuestMode !== undefined) setGuestMode(settings.GuestMode);
        if (settings.AdditionalEmails !== undefined && settings.AdditionalEmails != ""){
          setEnableAdditionalEmails(true);
          setAdditionalEmails(settings.AdditionalEmails);
        } else{
          setEnableAdditionalEmails(false);
          setAdditionalEmails("");
        }
        if (settings.powerCal !== undefined) setPowerCal(settings.powerCal);
        if (settings.enableMQTT !== undefined) setEnableMQTT(settings.enableMQTT);
        if (settings.mqttHost !== undefined) setMqttHost(settings.mqttHost);
        if (settings.mqttClient !== undefined) setMqttClient(settings.mqttClient);
        if (settings.mqttUser !== undefined) setMqttUser(settings.mqttUser);
        if (settings.mqttPassword !== undefined) setMqttPassword(settings.mqttPassword);
        if (settings.mqttPort !== undefined) setMqttPort(settings.mqttPort);
        if (settings.webhookURL !== undefined && settings.webhookURL !== null && settings.webhookURL != ""){
          setEnableWebhook(true);
          setWebhookURL(settings.webhookURL);
        } else{
          setEnableWebhook(false);
          setWebhookURL("");
        }
        if (settings.webhookKey !== undefined) setWebhookKey(settings.webhookKey);

        // Load messages from the database response
        if (settings.detections !== undefined) {
          const fileMessages = JSON.parse(settings.messages);
          const loadedMessages = Object.keys(fileMessages).map((key) => {
            const msg = fileMessages[key];
            return {
              id: parseInt(key.replace('MSG', ''), 10),
              eMSG: false,
              MSG: msg.MSG,
              count: msg.count,
              delay: msg.delay,
              interval: msg.interval,
              dropout: msg.dropout,
              action: msg.action,
              thresh: msg.thresh,
              direction: msg.direction,
              enableMoreSettings: false, // Adjust this based on your logic if needed
              value: msg.value  // Default value for the new dropdown field
            };
          });
          setMessages(loadedMessages);
        }

      }
      setIsLoading(false);
      setVisibleTrigger3(false);
    }

    async function saveSettingsToFile(settingsTemp) {
      // Create a settings object
      let settings = {};
      
      // If includeSettings is true, add all your settings
      // Add all your settings to the settings object
      settings = settingsTemp;
    
      // Create a JSON string from the settings object
      const json = JSON.stringify(settings);
    
      if (Platform.OS === 'web') {
        // Handle web platform
        const blob = new Blob([json], { type: 'text/plain;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
    
        a.href = url;
        a.download = 'settings.json';
        a.click();
    
        URL.revokeObjectURL(url);
        setVisibleTrigger3(false);
      } else {
        // Handle native platform
        const fileUri = FileSystem.documentDirectory + 'settings.json';
        await FileSystem.writeAsStringAsync(fileUri, json);
    
        if (!(await Sharing.isAvailableAsync())) {
          alert(`Sharing is not available on your platform`);
          return;
        }
        await Sharing.shareAsync(fileUri);
        setVisibleTrigger3(false);
      }
    }

    const loadExistingSettings = async (useDefaults) => {
      //setIsloading(true);
      //setIsLoading(false);
      const keepConnectCode = await keepConnectStore.get("keepConnect");
      const response = await getKeepConnectSettingsApi.request(keepConnectCode);
      //setExistingSettings(response.data);
      //console.log(response);
      //console.log(response);
      setVPNenabled(response.data[0].firmware.includes("VPN"));
      setBattEnable(response.data[0].firmware.includes("BATT"));

      const firmwareString = response.data[0].firmware;
      const majorVersionStart = firmwareString.indexOf("_V") + 2;
      const majorVersionEnd = firmwareString.indexOf("_", majorVersionStart);
      const majorVersion = parseInt(firmwareString.substring(majorVersionStart, majorVersionEnd), 10);

      setNewSettings5(majorVersion >= 2);
      setNewSettings2(majorVersion >= 3);
      setNewSettings1(majorVersion >= 2);

      //setNewSettings1(true);
      if(!useDefaults){

        //Do all the mapping here!
        //data[0].notificationType
        //console.log(response.data[0]);

        setSelectedNotifications(response.data[0].notificationType == "None" ? "noNotif" : response.data[0].notificationType);
        var StringPhoneNumber = String(response.data[0].phoneNumber);
        var PhoneNumberLength = StringPhoneNumber.length;
        if(PhoneNumberLength > 10){
          setDefaultCountryCode(StringPhoneNumber.substring(0, (PhoneNumberLength - 10)));
          setDefaultPhoneNumber(StringPhoneNumber.substring((PhoneNumberLength - 10), PhoneNumberLength));
        }
        setDefaultEmailAddress(response.data[0].emailAddress);
        setDefaultPollTime(String(Number(response.data[0].pollTime)/60000));
        setDefaultOffline(String(Number(response.data[0].offlineCloudNotificationDelay)));
        setDefaultOffMsg(String(response.data[0].cloudMsg));
        setDefaultOnMsg(String(response.data[0].cloudRestorationMsg));
        if(response.data[0].enableStaticIP == "true"){
          setEnableStaticIP(true);
          setDefaultLocalIP(response.data[0].localIP);
          setDefaultLocalSubnet(response.data[0].localSubnet);
          setDefaultLocalGateway(response.data[0].localGateway);
          setDefaultLocalDNS1(response.data[0].localDNS1);
          setDefaultLocalDNS2(response.data[0].localDNS2);
        } else{
          setEnableStaticIP(false);
          setDefaultLocalIP("");
          setDefaultLocalSubnet("");
          setDefaultLocalGateway("");
          setDefaultLocalDNS1("");
          setDefaultLocalDNS2("");
        }
        setSelected802_11Mode(response.data[0].wifiMode);
        setReduceTxPower(Number(response.data[0].reduceTransmit) == 1 ? true: false);
        let StringSSID = String(response.data[0].customSSID);
        let StringMAC = String(response.data[0].macAddress);
        setVPNmode(response.data[0].vpnMode != null ? response.data[0].vpnMode : "0");
        setVPNport(response.data[0].vpnPort != null ? response.data[0].vpnPort : "4550");

        let checkIfDefaultSSID = (response.data[0].firmware.includes("PowerPal") ? "PowerPal-" : "KeepConnect-") + (StringMAC.substring(StringMAC.length - 5, StringMAC.length)).replace(":", "");
        //console.log(checkIfDefaultSSID);
        if(StringSSID != checkIfDefaultSSID){
        setEnableCustomSSID(true);
        setDefaultLocalSSID(response.data[0].customSSID);
        } else{
          setEnableCustomSSID(false);
          setDefaultLocalSSID("");
        }
        setEnableWebPortal(response.data[0].webPortal == "true" ? true : false);
        if (response.data[0].enableKCRoundtrip == "true") {
          setConnectionMonitorMode("KCRoundtrip");
        } else {
            setConnectionMonitorMode(response.data[0].enablePingOnly == "true" ? "Only Monitor Using Ping" : "Fullstack");
        }
        //new V5 settings
        setDelay2(response.data[0].delay2);
        // Create offline and online JSON structures
        let KCCS_Meta = JSON.parse(response.data[0].KCCS_Meta);
        if (KCCS_Meta){
          setCount1(KCCS_Meta.offline.count);
          setCount2(KCCS_Meta.online.count);
          setInterval1(KCCS_Meta.offline.interval);
          setInterval2(KCCS_Meta.online.interval);
          setDropout1(KCCS_Meta.offline.dropout);
          setDropout2(KCCS_Meta.online.dropout);
        }
        setGuestMode(response.data[0].GuestMode);
        if (response.data[0].AdditionalEmails != "" && response.data[0].AdditionalEmails != null){
          setEnableAdditionalEmails(true);
          setAdditionalEmails(response.data[0].AdditionalEmails);
        } else{
          setEnableAdditionalEmails(false);
          setAdditionalEmails("");
        }
        setPowerCal(response.data[0].powerCal);
        if (response.data[0].enableMQTT !== undefined) setEnableMQTT(Number(response.data[0].enableMQTT) == 1);
        if (response.data[0].mqttHost !== undefined) setMqttHost(response.data[0].mqttHost);
        if (response.data[0].mqttClient !== undefined) setMqttClient(response.data[0].mqttClient);
        if (response.data[0].mqttUser !== undefined) setMqttUser(response.data[0].mqttUser);
        if (response.data[0].mqttPassword !== undefined) setMqttPassword(response.data[0].mqttPassword);
        if (response.data[0].mqttPort !== undefined) setMqttPort(response.data[0].mqttPort);
        if (response.data[0].webhookURL !== undefined && response.data[0].webhookURL !== null && response.data[0].webhookURL !== ""){
          setEnableWebhook(true);
          setWebhookURL(response.data[0].webhookURL);
        } else{
          setEnableWebhook(false);
          setWebhookURL("");
        }
        if (response.data[0].webhookKey !== undefined) setWebhookKey(response.data[0].webhookKey);

        // Load messages from the database response
        if (response.data[0].detections) {
          const dbMessages = JSON.parse(response.data[0].detections);
          const loadedMessages = Object.keys(dbMessages).map((key) => {
            const msg = dbMessages[key];
            return {
              id: parseInt(key.replace('MSG', ''), 10),
              eMSG: false,
              MSG: msg.MSG,
              count: msg.count,
              delay: msg.delay,
              interval: msg.interval,
              dropout: msg.dropout,
              action: msg.action,
              thresh: msg.thresh,
              direction: msg.direction,
              enableMoreSettings: false, // Adjust this based on your logic if needed
              value: msg.value  // Default value for the new dropdown field
            };
          });
          setMessages(loadedMessages);
        }
      }
      setIsLoading(false);
      }

    const initializeSettings = async (useDefaults) => {
      setVisibleTrigger2(!visibleTrigger2);
      setIsLoading(true);
      loadExistingSettings(useDefaults);
      
    };

    const handleSubmit = async () => {
        toggleModal();
        const response = await changeSettingsKeepConnectApi.request(stagedSettings);
        if (!response.ok){
            setIsLoading(false);
            return setRequestFailed(true);
        } 
        setServerMessage("Unknown Server Error");
        if( !response.data || !response.data.MSG ){
          setIsLoading(false);
          return setErrorFromServer(true);
        }
        if (response.data.MSG != "Success!"){
          setErrorFromServer(true);
          setServerMessage(response.data.MSG);
          //console.log(response.data);
          setIsLoading(false);
          return;
        }
        alert(
          "Success!",
          "Settings were staged successfully. Your device will download and apply these in appx 5min.",
          [
            { text: "OK", onPress: () => {} }
          ]
        );
        triggerHeartbeat(keepConnectCode);
        route.params.changedDevices(true); //for refreshing upstream screen
        setRequestFailed(false);
        setErrorFromServer(false);
        setServerMessage("");
        };



        const addMessage = () => {
          const newMessage = {
            id: messages.length + 1, // sequential id starting from 1
            eMSG: true,
            MSG: 'Climate Pal: High Temperature',
            thresh: '100',
            delay: '0',
            count: '1',
            interval: '0',
            dropout: '60',
            enableMoreSettings: false,
            direction: '1',
            action: 'none',
            value: 'Temp'  // Default value for the new dropdown field
          };
          setMessages([...messages, newMessage]);
        };
      
        const removeMessage = (id) => {
          const updatedMessages = messages.filter(message => message.id !== id);
          setMessages(updatedMessages);
        };
      
        const handleSwitchChange = (id, field, value) => {
          const updatedMessages = messages.map(message =>
            message.id === id ? { ...message, [field]: value } : message
          );
          setMessages(updatedMessages);
        };
      
        const valueOptions = [
          { label: 'Temperature (F or C)', value: 'Temp' },
          { label: 'Humidity (%)', value: 'Hum' },
          ...(battEnable ? [{ label: 'Battery (%)', value: 'Batt' }] : [])
        ];

    
    return(
    <Screen>
        {isLoading ? <ActivityIndicator size="large" color={colors.primary}/> :
        <React.Fragment>
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Enter New Settings for Keep Connect:</Text>
        <Text style={{alignSelf: "center"}}>{route.params.item.Name}</Text>
        <View style={{padding: 20, borderBottomWidth :3, borderBottomColor: colors.primary}}>
        <Form
        initialValues={{ email: defaultEmailAddress, phoneNumber: defaultPhoneNumber, countryCode: defaultCountryCode, pollPeriod: defaultPollTime, offline: defaultOffline, onMsg: defaultOnMsg, offMsg: defaultOffMsg, ip: defaultLocalIP, sm: defaultLocalSubnet, gw: defaultLocalGateway, dns1: defaultLocalDNS1, dns2: defaultLocalDNS2, ssid: defaultLocalSSID, vpnP: vpnPort, delay2, count1, count2, interval1, interval2, dropout1, dropout2, GuestMode, powerCal, AdditionalEmails, mqttHost, mqttClient, mqttUser, mqttPassword, mqttPort, webhookURL, webhookKey }} //order doesn't matter since these are key:values
        onSubmit={initiateSubmit}
        validationSchema={validationSchema}
        >
        <Dropdown
          labelText='Select Notification Type'
          defaultValueProp={selectedNotifications}
          itemsArray= {[
            {label: 'No Notifications', value: 'noNotif'},
            {label: 'Email Notifications', value: 'Email' },
            {label: 'SMS Notifications', value: 'SMS' },
            {label: 'Email and SMS', value: 'BOTH' },
            ]}
          onChangeItemProp={setSelectedNotifications}
        />
        {(selectedNotifications==='Email' || selectedNotifications == 'BOTH') && <><FormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="email"
          keyboardType="email-address"
          name="email"
          placeholder="Email"
          textContentType="emailAddress"
        />
        
        {<Switch
            labelText='Enable Additional Email Addresses'
            switchState={enableAdditionalEmails}
            setSwitchState={setEnableAdditionalEmails} 
            isDisabled={false}
          />}
          {enableAdditionalEmails && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="AdditionalEmails"
            placeholder=""
            labelText={"Additional Email Addresses\n(Comma Separated)"}
            multiline={true}
            maxLength={198}
            textAlignVertical="top"
          />}</>
        }
        
        {(selectedNotifications==='SMS' || selectedNotifications == 'BOTH') && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="cellphone"
          keyboardType="numeric"
          name="phoneNumber"
          placeholder="XXXXXXXXXX"
          labelText='Enter Phone Number (10 Digits)'
        />}
        {(selectedNotifications==='SMS' || selectedNotifications == 'BOTH') && <LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          icon="plus"
          keyboardType="numeric"
          name="countryCode"
          placeholder="1"
          labelText = "Enter Country Code"
        />}

          {enterpriseOrPro && <Switch
            labelText='Enable Webhook'
            switchState={enableWebhook}
            setSwitchState={setEnableWebhook} 
            isDisabled={false}
          />}
          {enableWebhook && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="webhookURL"
            placeholder=""
            labelText={"Enter Webhook URL"}
            maxLength={250}
            textAlignVertical="top"
          />}
          {enableWebhook && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="webhookKey"
            placeholder=""
            labelText={"Enter Webhook Key\n(Optional) Default=text"}
            maxLength={50}
            textAlignVertical="top"
          />}
        <Text style={{fontWeight: "bold", alignSelf: "center"}}>Detections and Notifications:</Text>
        
        <Button title="Add New Detection" onPress={addMessage} />
        {messages.map((message, index) => (
          <View key={message.id} style={styles.deviceBox}>
            <Switch
              labelText={`Show Detection ${index + 1}`}
              switchState={message.eMSG}
              setSwitchState={(value) => handleSwitchChange(message.id, 'eMSG', value)}
              isDisabled={false}
            />
            {message.eMSG && (
              <>
                <Dropdown
                  labelText="Detection Type"
                  defaultValueProp={message.value}
                  itemsArray={valueOptions}
                  onChangeItemProp={(value) => handleSwitchChange(message.id, 'value', value)}
                />
                <LabelFormFieldWithoutFormik
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="numeric"
                  name={`thresh${index + 1}`}
                  placeholder="Threshold"
                  labelText="Threshold"
                  value={message.thresh}
                  onChangeText={(value) => handleSwitchChange(message.id, 'thresh', value)}
                />
                <Dropdown
                  labelText="Direction"
                  defaultValueProp={message.direction}
                  itemsArray={[
                    { label: 'Greater Than', value: '1' },
                    { label: 'Less Than', value: '0' }
                  ]}
                  onChangeItemProp={(value) => handleSwitchChange(message.id, 'direction', value)}
                />
                <LabelFormFieldWithoutFormik
                  autoCapitalize="none"
                  autoCorrect={false}
                  keyboardType="default"
                  name={`MSG${index + 1}`}
                  placeholder={`Message ${index + 1}`}
                  labelText={`Message ${index + 1}`}
                  multiline={true}
                  maxLength={98}
                  textAlignVertical="top"
                  value={message.MSG}
                  onChangeText={(value) => handleSwitchChange(message.id, 'MSG', value)}
                />
                <Switch
                  labelText="More Message Settings"
                  switchState={message.enableMoreSettings}
                  setSwitchState={(value) => handleSwitchChange(message.id, 'enableMoreSettings', value)}
                  isDisabled={false}
                />
                {message.enableMoreSettings && (
                  <>
                    <LabelFormFieldWithoutFormik
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name={`delay${index + 1}`}
                      placeholder="Delay"
                      labelText="Notification Delay (Seconds)"
                      value={message.delay}
                      onChangeText={(value) => handleSwitchChange(message.id, 'delay', value)}
                    />
                    <LabelFormFieldWithoutFormik
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name={`count${index + 1}`}
                      placeholder="Count"
                      labelText="Message Resend Count"
                      value={message.count}
                      onChangeText={(value) => handleSwitchChange(message.id, 'count', value)}
                    />
                    <LabelFormFieldWithoutFormik
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name={`interval${index + 1}`}
                      placeholder="Interval"
                      labelText="Message Resend Interval (Minutes)"
                      value={message.interval}
                      onChangeText={(value) => handleSwitchChange(message.id, 'interval', value)}
                    />
                    <LabelFormFieldWithoutFormik
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name={`dropout${index + 1}`}
                      placeholder="Dropout"
                      labelText="Message Re-arm Dropout (Minutes)"
                      value={message.dropout}
                      onChangeText={(value) => handleSwitchChange(message.id, 'dropout', value)}
                    />
                  </>
                )}
                <Button title="Remove Detection" onPress={() => removeMessage(message.id)} />
              </>
            )}
          </View>
        ))}

        <View style={styles.deviceBox}>
          <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="offMsg"
            placeholder=""
            labelText="Offline Message"
            multiline={true}
            maxLength={98}
            textAlignVertical="top"
          />
          {<LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType="numeric"
          name="offline"
          placeholder="6"
          labelText = {"Offline Notification Delay\n(Minutes)"}
          />}
          {newSettings5 && <Switch 
            labelText='More Offline Message Settings'
            switchState={enableMsg1More}
            setSwitchState={setEnableMsg1More} 
            isDisabled={false}
          />}
          {enableMsg1More && (
            <>
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="count1"
                placeholder="0"
                labelText="Message Resend Count"
              />
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="interval1"
                placeholder="0"
                labelText={"Message Resend Interval\n(Minutes)"}
              />
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="dropout1"
                placeholder="60"
                labelText={"Message Re-arm Dropout\n(Minutes)"}
              />
            </>
          )}
        </View>

        <View style={styles.deviceBox}>
          <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="default"
            name="onMsg"
            placeholder=""
            labelText="Restoration Message"
            multiline={true}
            maxLength={98}
            textAlignVertical="top"
          />
          {newSettings5 && <Switch 
            labelText='More Restoration Message Settings'
            switchState={enableMsg2More}
            setSwitchState={setEnableMsg2More} 
            isDisabled={false}
          />}
          {enableMsg2More && (
            <>
              {/* <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="delay2"
                placeholder="0"
                labelText={"Notification Delay\n(Minutes)"}
              /> */}
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="count2"
                placeholder="0"
                labelText="Message Resend Count"
              />
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="interval2"
                placeholder="0"
                labelText={"Message Resend Interval\n(Minutes)"}
              />
              <LabelFormField
                autoCapitalize="none"
                autoCorrect={false}
                keyboardType="numeric"
                name="dropout2"
                placeholder="60"
                labelText={"Message Re-arm Dropout\n(Minutes)"}
              />
            </>
          )}
        </View>

        {<LabelFormField
          autoCapitalize="none"
          autoCorrect={false}
          keyboardType="numeric"
          name="pollPeriod"
          placeholder="1"
          labelText = {"Heartbeat Interval\n(Minutes)"}
        />}
        <Switch 
          labelText='Show Advanced Settings'
          switchState={enableAdvanced}
          setSwitchState={setEnableAdvanced} 
          isDisabled = {false}
        />
        {enableAdvanced && newSettings5 && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && newSettings1 && <Text style={{fontWeight: "bold", alignSelf: "center"}}>Miscellaneous Settings:</Text>}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Static IP'
          switchState={enableStaticIP}
          setSwitchState={setEnableStaticIP} 
          isDisabled = {false}
        />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="ip"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"IP Address"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="sm"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Subnet Mask"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="gw"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"Gateway"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns1"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 1"}
                  />}
        {enableAdvanced && enableStaticIP  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="dns2"
                    placeholder="xxx.xxx.xxx.xxx"
                    labelText = {"DNS Server 2"}
                  />}
        {enableAdvanced && newSettings1 && <Dropdown
          labelText='Select 802.11 Wireless Mode'
          defaultValueProp={wifi802_11Mode}
          itemsArray= {[
            {label: '802.11b/g/n', value: '3'},
            {label: '802.11b/g', value: '2' },
            {label: '802.11b', value: '1' },
            ]}
          onChangeItemProp={setSelected802_11Mode}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Reduce Wireless Transmit power'
          switchState={reduceTxPower}
          setSwitchState={setReduceTxPower} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings5 && <Switch 
          labelText='Enable Guest Mode'
          switchState={GuestMode}
          setSwitchState={setGuestMode} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings5 && <LabelFormField
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType="numeric"
            name="powerCal"
            placeholder="1"
            labelText = {"Power Calibration Factor"}
          />}
        {enableAdvanced && newSettings5 && enterpriseOrPro && <Switch 
          labelText='Enable MQTT'
          switchState={enableMQTT}
          setSwitchState={setEnableMQTT} 
          isDisabled = {false}
        />}
                    {enableAdvanced && enableMQTT && <>
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttHost"
                      placeholder=""
                      labelText="MQTT Host URL"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttClient"
                      placeholder=""
                      labelText="MQTT Client Name"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttUser"
                      placeholder=""
                      labelText="MQTT Username"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="default"
                      name="mqttPassword"
                      placeholder=""
                      labelText="MQTT Password"
                      maxLength={98}
                      textAlignVertical="top"
                    />
                    <LabelFormField
                      autoCapitalize="none"
                      autoCorrect={false}
                      keyboardType="numeric"
                      name="mqttPort"
                      placeholder="1883"
                      labelText={"MQTT Port"}
                    />
                    </>}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Enable Local Web Portal'
          switchState={enableWebPortal}
          setSwitchState={setEnableWebPortal} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && <Switch 
          labelText='Use Custom Keep Connect SSID'
          switchState={enableCustomSSID}
          setSwitchState={setEnableCustomSSID} 
          isDisabled = {false}
        />}
        {enableAdvanced && newSettings1 && enableCustomSSID  && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="default"
                    name="ssid"
                    placeholder=""
                    labelText = {"Custom WiFi SSID"}
                  />}
        {enableAdvanced && <View style={{paddingTop: 20, borderTopWidth :3, borderTopColor: colors.primary}}></View>}
        {enableAdvanced && <Text style={{fontWeight: "bold", alignSelf: "center"}}>VPN Endpoint Settings:</Text>}
        {enableAdvanced && <Dropdown
          key={vpnMode}
          labelText='VPN Mode'
          defaultValueProp={vpnMode}
          itemsArray= {[
            {label: 'Disabled', value: '0'},
            {label: 'Auto', value: '1' },
            {label: 'Direct', value: '2' },
          ]}
          disabled = {!vpnEnabled || !enterpriseOrPro}
          onChangeItemProp={setVPNmode}
        />}
        {enableAdvanced && !enterpriseOrPro && <Text style={{fontSize: 12, color: "gray"}}>VPN Functionality requires Enterprise Subscription</Text>}
        {enableAdvanced && enterpriseOrPro && !vpnEnabled && <Text style={{fontSize: 12, color: "gray"}}>Firmware Upgrade Required to Enable</Text>}
        {enableAdvanced && vpnMode == 2 && <LabelFormField
                    autoCapitalize="none"
                    autoCorrect={false}
                    keyboardType="numeric"
                    name="vpnP"
                    placeholder=""
                    labelText = {"Device Listening Port"}
                  />}
        <Text></Text>
      <ErrorMessage
          error={requestMessage}
          visible={requestFailed}
        />
        <ErrorMessage
          error={serverMessage}
          visible={errorFromServer}
        />
      <SubmitButton title="Send Settings to Device" />
      <Button title="Import/Export Settings" onPress={() => setVisibleTrigger3(true)} />
        <Modal isVisible={visibleTrigger3} transparent={true} animationType = {"slide"} onBackdropPress={()=>setVisibleTrigger3(false)}>
          <View style={{height: 300,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
              <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
              <Button title="Import Settings" onPress={()=>{loadSettingsFromFile();}} />
              <SubmitButton title="Export Settings" customAction={() => setSaveForExport(true)} />
            </View>
          </View>
        </Modal>
      </Form>
      <Modal isVisible={visibleTrigger} transparent={true} animationType = {"slide"}>
            <View style={{height: 250,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style = {{alignSelf: "center"}}>Are you sure you want to send these settings to:{'\n'}{route.params.item.Name}</Text>
            <Button title="Send It!" onPress={()=>handleSubmit()} />
            <Button title="Cancel" onPress={()=>cancelSend()} />
            </View>
            </View>
        </Modal>
        <Modal isVisible={visibleTrigger2} transparent={true} animationType = {"slide"} onBackdropPress={()=>navigation.goBack()}>
            <View style={{height: 200,justifyContent: "center", alignItems: "center", borderRadius: 20, backgroundColor: "white"}}>
            <View style={{width:300}}>
            <Text style={{alignSelf: "center"}}>How would you like to proceed?{'\n'}</Text>
            <Button title="View/Modify Settings" onPress={()=>initializeSettings(false)} />
            <Button title="Setup from Defaults" onPress={()=>initializeSettings(true)} />
            </View>
            </View>
        </Modal>
      </View>
      </React.Fragment>}
    </Screen>
);
    }

const styles = StyleSheet.create({
      deviceBox: {
        borderWidth: 1,
        borderRadius: 20,
        borderColor: '#4eb9d6',
        padding: 10,
        marginBottom: 10,
    },
  });

export default ChangeSettingsScreenClimatePal;