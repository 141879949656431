export default Object.freeze({
  LISTING_DETAILS: "ListingDetails",
  LISTING_EDIT: "ListingEdit",
  LOGIN: "Login",
  MESSAGES: "Messages",
  REGISTER: "Register",
  DASHBOARD: "DashboardScreen",
  DASHBOARDCOMPREHENSIVE: "DashboardScreenComprehensive",
  OVERVIEW: "OverviewScreen",
  DEVICE: "DeviceScreen",
  APPSETTINGS: "AppSettingsScreen",
  USERMANAGEMENT: "UserManagementScreen",
  MESSAGESETTINGS: "MessageSettingsScreen",
  PRESENTSETTINGS: "PresentSettingsScreen",
  PRESENTSETTINGSWATER: "PresentSettingsScreenWater",
  PRESENTSETTINGSCLIMATE: "PresentSettingsScreenClimate",
  CHANGESETTINGS: "ChangeSettingsScreen",
  CHANGESETTINGSPOWERPAL: "ChangeSettingsScreenPowerPal",
  CHANGESETTINGSWATERPAL: "ChangeSettingsScreenWaterPal",
  CHANGESETTINGSCLIMATEPAL: "ChangeSettingsScreenClimatePal",
  CHANGESETTINGSCOMPUTERPAL: "ChangeSettingsScreenComputerPal",
  TRIGGERRESET: "TriggerResetScreen",
  CLOUDNOTIFICATIONS: "CloudNotificationsScreen",
  ADDREMOVEKEEPCONNECTS: "AddRemoveKeepConnectsScreen",
  ADDDEVICE: "AddKeepConnectsScreen",
  SUBACCOUNTDEVICES: "SubaccountDevicesScreen",
  PIPELINES: "PipelinesScreen",
  SCHEDULEEVENT: "ScheduleEventScreen",
});
