import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { MaterialCommunityIcons, Foundation } from '../components/PlatformSpecificIcons';
import PlatformModal from '../components/PlatformModal';
import AppTextInput from '../components/TextInput';
import colors from '../config/colors';

const ModifyFolderModal = forwardRef(({ handleModifyFolder }, ref) => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [isRenaming, setIsRenaming] = useState(false);
    const [newFolderName, setNewFolderName] = useState('');
    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
        openModal: (folderId, currentName) => {
            setCurrentFolderId(folderId);
            setNewFolderName(currentName);
            setIsRenaming(false);
            setIsVisible(true);
        },
        closeModal: () => setIsVisible(false),
    }));

    const handleAction = (action) => {
        if (action === 'rename') {
            setIsRenaming(true);
        } else {
            handleModifyFolder(action, currentFolderId);
            setIsVisible(false);
        }
    };

    const handleSave = () => {
        handleModifyFolder('rename', currentFolderId, newFolderName);
        setIsVisible(false);
    };

    const handleCancel = () => {
        setIsRenaming(false);
        setIsVisible(false);
    };

    return (
        <PlatformModal
            isVisible={isVisible}
            onBackdropPress={handleCancel}
            animationType="fade"
        >
            <View style={styles.modalContent}>
                {!isRenaming ? (
                    <>
                        <TouchableOpacity 
                            style={styles.optionItem} 
                            onPress={() => handleAction('rename')}
                        >
                            <MaterialCommunityIcons name="pencil" size={24} color={colors.primary} />
                            <Text style={styles.optionText}>Rename Folder</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={styles.optionItem} 
                            onPress={() => {setIsVisible(false); handleAction('move')}}
                        >
                            <MaterialCommunityIcons name="folder-move" size={24} color={colors.primary} />
                            <Text style={styles.optionText}>Move Folder</Text>
                        </TouchableOpacity>
                        
                        <TouchableOpacity 
                            style={styles.optionItem} 
                            onPress={() => {setIsVisible(false); handleAction('delete');}}
                        >
                            <Foundation name="trash" size={24} color="red" />
                            <Text style={[styles.optionText, { color: 'red' }]}>Delete Folder</Text>
                        </TouchableOpacity>
                    </>
                ) : (
                    <>
                        <AppTextInput
                            icon="folder"
                            placeholder="New folder name"
                            value={newFolderName}
                            onChangeText={setNewFolderName}
                            inputRef={inputRef}
                        />
                        <TouchableOpacity 
                            style={[styles.optionItem, styles.saveButton]} 
                            onPress={handleSave}
                        >
                            <MaterialCommunityIcons name="content-save" size={24} color={colors.primary} />
                            <Text style={styles.optionText}>Save</Text>
                        </TouchableOpacity>
                    </>
                )}
                
                <TouchableOpacity 
                    style={[styles.optionItem, styles.cancelButton]} 
                    onPress={handleCancel}
                >
                    <MaterialCommunityIcons name="close" size={24} color={colors.medium} />
                    <Text style={[styles.optionText, { color: colors.medium }]}>Cancel</Text>
                </TouchableOpacity>
            </View>
        </PlatformModal>
    );
});

const styles = StyleSheet.create({
    modalContent: {
        backgroundColor: 'white',
        padding: 22,
        justifyContent: 'center',
        alignItems: 'stretch',
        borderRadius: 20,
        borderColor: 'rgba(0, 0, 0, 0.1)',
    },
    optionItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 15,
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    optionText: {
        marginLeft: 10,
        fontSize: 16,
        color: colors.dark,
    },
    saveButton: {
        backgroundColor: colors.light,
        borderRadius: 10,
        marginTop: 10,
    },
    cancelButton: {
        justifyContent: 'center',
        marginTop: 10,
    },
});

export default ModifyFolderModal;