import React, { useState, useEffect } from "react";
import { StyleSheet, View, Text, ActivityIndicator, FlatList, TouchableOpacity, Alert, ScrollView, Dimensions, Platform } from "react-native";
import Screen from '../components/Screen';
import Dropdown from '../components/Dropdown';
import Button from '../components/Button';
import useApi from '../hooks/useApi';
import keepConnectApi from '../api/keepConnectsApi';
import colors from '../config/colors';
import PlatformModal from '../components/PlatformModal';

//const { width: screenWidth } = Dimensions.get('window');

function SubaccountDevicesScreen({ navigation, route }) {
    const { subaccountId } = route.params;
    const [devices, setDevices] = useState([]);
    const [enterpriseDevices, setEnterpriseDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [attachModalVisible, setAttachModalVisible] = useState(false);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);
    const [selectedEnterpriseDevice, setSelectedEnterpriseDevice] = useState(null);

    const getSubaccountDevices = useApi(keepConnectApi.getKeepConnects);
    const getEnterpriseDevices = useApi(keepConnectApi.getKeepConnects);
    const removeDevice = useApi(keepConnectApi.removeKeepConnect);
    const attachDevice = useApi(keepConnectApi.registerKeepConnect);

    useEffect(() => {
        const initialize = async () => {
            loadEnterpriseDevices(); // Fire off loading enterprise devices, don't await
            await loadDevices(); // Wait for loadDevices to complete
        };
        initialize();
    }, []);

    const loadDevices = async () => {
        setIsLoading(true);
        const response = await getSubaccountDevices.request(subaccountId);
        if (!response.ok) {
            setIsLoading(false);
            return Alert.alert("Error", "Failed to load devices");
        }
    
        const devicesArray = Object.entries(response.data[0]).map(([name, mac]) => ({
            Name: name,
            MAC: mac,
        }));
        
        //console.log(enterpriseDevices); // For debugging
    
        setDevices(devicesArray);
        setIsLoading(false);
    };

    const loadEnterpriseDevices = async () => {
        setIsLoading(true);
        const response = await getEnterpriseDevices.request();
        if (!response.ok) {
            setIsLoading(false);
            return Alert.alert("Error", "Failed to load enterprise devices");
        }
    
        const enterpriseDevicesArray = Object.entries(response.data[0]).map(([name, mac]) => ({
            Name: name,
            MAC: mac,
        }));        
    
        setEnterpriseDevices(enterpriseDevicesArray);
        setIsLoading(false);
    };

    const handleDeleteDevice = async () => {
        setModalVisible(false);
        setIsLoading(true);
        const response = await removeDevice.request(selectedDeviceId, subaccountId);
        if (!response.data?.MSG === 'Success!') {
            setIsLoading(false);
            return Alert.alert("Error", "Failed to delete device");
        }
        await loadDevices();
    };

    const handleAttachDevice = async () => {
        setAttachModalVisible(false);
        if (!selectedEnterpriseDevice) {
            return Alert.alert("Error", "Please select a device to attach");
        }
        setIsLoading(true);
        
        const deviceToAttach = enterpriseDevices.find(d => d.MAC === selectedEnterpriseDevice);
        if (!deviceToAttach) {
            setIsLoading(false);
            return Alert.alert("Error", "Selected device not found");
        }
        
        // Use the MAC address directly, making sure to trim and remove any potential leading/trailing whitespace
        const sanitizedMAC = deviceToAttach.MAC.trim();
    
        //console.log("Device to attach: ", deviceToAttach);
    
        // Make sure to pass the WebID, sanitized MAC, and Name exactly as expected by the API
        const response = await attachDevice.request(deviceToAttach.WebID, sanitizedMAC, deviceToAttach.Name, null, subaccountId);
        //console.log(response);
        if (!response.data?.MSG === 'Success!') {
            setIsLoading(false);
            return Alert.alert("Error", "Failed to attach device");
        }
        
        await loadDevices();
    };
    
    const renderDeviceItem = ({ item }) => (
        <TouchableOpacity
            style={styles.deviceItem}
            onPress={() => {
                setSelectedDeviceId(item.MAC);
                setModalVisible(true);
            }}
        >
            <Text style={styles.deviceName}>{item.Name}</Text>
            <Text style={styles.deviceDetails}>MAC: {item.MAC}</Text>
        </TouchableOpacity>
    );

    return (
        <Screen style={styles.screen}>
            <Text style={styles.title}>Subaccount Devices</Text>
            {isLoading ? (
                <View style={styles.loader}>
                    <ActivityIndicator size="large" color={colors.primary} />
                </View>
            ) : (
                <FlatList
                    data={devices}
                    keyExtractor={(item) => item.MAC}
                    renderItem={renderDeviceItem}
                    style={styles.deviceList}
                />
            )}

            <Button title="Attach Device to Customer Account" onPress={() => {
                setAttachModalVisible(true);
                //loadEnterpriseDevices();
            }} />

            {/* Modal for Deleting Device */}
            <PlatformModal isVisible={modalVisible} transparent={false} animationType="slide" onBackdropPress={() => setModalVisible(false)}>
                <View style={styles.modalContent}>
                    <Text style={styles.modalTitle}>Delete Device</Text>
                    <Text style={styles.modalMessage}>Are you sure you want to delete this device?</Text>
                    <Button title="Delete" onPress={handleDeleteDevice} />
                    <Button title="Cancel" onPress={() => setModalVisible(false)} />
                </View>
            </PlatformModal>

            {/* Modal for Attaching Device */}
            <PlatformModal isVisible={attachModalVisible} transparent={true} animationType="slide" onBackdropPress={() => setAttachModalVisible(false)}>
                {/* <View style={styles.modalContainer}> */}
                    <ScrollView contentContainerStyle={styles.modalContent}>
                        <Text style={styles.modalTitle}>Attach Device to Customer Account</Text>
                        <View style={styles.dropdownContainer}>
                            <Dropdown
                                labelText="Select Device"
                                defaultValueProp={selectedEnterpriseDevice}
                                itemsArray={enterpriseDevices.map(device => ({ label: device.Name, value: device.MAC }))}
                                onChangeItemProp={setSelectedEnterpriseDevice}
                            />
                        </View>
                        <Button title="Attach" onPress={handleAttachDevice} />
                        <Button title="Cancel" onPress={() => setAttachModalVisible(false)} />
                    </ScrollView>
                {/* </View> */}
            </PlatformModal>
        </Screen>
    );
}

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        alignItems: 'stretch',
        paddingHorizontal: 15,
    },
    title: {
        paddingTop: 20,
        fontWeight: "bold",
        alignSelf: "center",
        fontSize: 24,
        marginBottom: 20,
    },
    loader: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    deviceList: {
        flex: 1,
        marginBottom: 20,
    },
    deviceItem: {
        padding: 15,
        backgroundColor: 'white',
        borderBottomWidth: 1,
        borderBottomColor: colors.light,
    },
    deviceName: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    deviceDetails: {
        fontSize: 16,
        color: colors.medium,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    modalContent: {
        flexGrow: 1,
        backgroundColor: "white",
        borderRadius: 20,
        padding: 40,
        alignItems: "stretch",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: '95%',
    },
    modalTitle: {
        marginBottom: 20,
        textAlign: "center",
        fontWeight: "bold",
        fontSize: 22,
    },
    modalMessage: {
        marginBottom: 20,
        textAlign: "center",
        fontSize: 18,
    },
    dropdownContainer: {
        marginBottom: 20,
        zIndex: Platform.OS === 'ios' ? 1000 : undefined,
    },
});

export default SubaccountDevicesScreen;