import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import OverviewScreen from '../screens/OverviewScreen';
import DeviceScreen from '../screens/DeviceScreen';
import DashboardScreen from '../screens/DashboardScreen';
import DashboardScreenComprehensive from '../screens/DashboardScreenComprehensive';
import PresentSettingsScreen from '../screens/PresentSettingsScreen';
import PipelinesScreen from '../screens/PipelinesScreen';
import AppSettingsScreen from '../screens/AppSettingsScreen';
import MessageSettingsScreen from '../screens/MessageSettingsScreen';
import ChangeSettingsScreen from '../screens/ChangeSettingsScreen';
import ChangeSettingsScreenPowerPal from '../screens/ChangeSettingsScreenPowerPal';
import ChangeSettingsScreenWaterPal from '../screens/ChangeSettingsScreenWaterPal';
import ChangeSettingsScreenClimatePal from '../screens/ChangeSettingsScreenClimatePal';
import ChangeSettingsScreenComputerPal from '../screens/ChangeSettingsScreenComputerPal';
import CloudNotificationsScreen from '../screens/CloudNotificationsScreen';
// import TriggerResetScreen from '../screens/TriggerResetScreen';
import AddKeepConnectsScreen from '../screens/AddKeepConnect';
import UserManagementScreen from '../screens/EnterpriseUserManagementScreen';
import SubaccountDevicesScreen from '../screens/EnterpriseDeviceManagement';
import ScheduleEventScreen from '../screens/ScheduleEventScreen';



const Stack = createStackNavigator();
//console.log("App Navigator");
const AppNavigator = () => (

    <Stack.Navigator>
        <Stack.Screen name="OverviewScreen" component={OverviewScreen} options={{ title: "Overview Screen" }} />
        <Stack.Screen name="DeviceScreen" component={DeviceScreen} options={{ title: "Device Screen" }} />
        <Stack.Screen name="AddKeepConnectsScreen" component={AddKeepConnectsScreen} options={{ title: "Add Device" }} />
        <Stack.Screen name="UserManagementScreen" component={UserManagementScreen} options={{ title: "User Management" }} />
        <Stack.Screen name="SubaccountDevicesScreen" component={SubaccountDevicesScreen} options={{ title: "Manage Subaccount" }} />
        <Stack.Screen name="PresentSettingsScreen" component={PresentSettingsScreen} options={{ title: "Device Status" }} />
        <Stack.Screen name="ChangeSettingsScreen" component={ChangeSettingsScreen} options={{ title: "Device Settings" }} />
        <Stack.Screen name="DashboardScreen" component={DashboardScreen} options={{ title: "Charts" }} />
        <Stack.Screen name="DashboardScreenComprehensive" component={DashboardScreenComprehensive} options={{ title: "Comprehensive Logs" }} />
        <Stack.Screen name="CloudNotificationsScreen" component={CloudNotificationsScreen} options={{ title: "Cloud Notifications" }} />
        <Stack.Screen name="PipelinesScreen" component={PipelinesScreen} options={{ title: "Configure Pipelines" }} />
        <Stack.Screen name="AppSettingsScreen" component={AppSettingsScreen} options={{ title: "App Settings" }} />
        <Stack.Screen name="MessageSettingsScreen" component={MessageSettingsScreen} options={{ title: "Message Settings" }} />
        <Stack.Screen name="ChangeSettingsScreenPowerPal" component={ChangeSettingsScreenPowerPal} options={{ title: "Device Settings" }} />
        <Stack.Screen name="ChangeSettingsScreenComputerPal" component={ChangeSettingsScreenComputerPal} options={{ title: "Device Settings" }} />
        <Stack.Screen name="ChangeSettingsScreenWaterPal" component={ChangeSettingsScreenWaterPal} options={{ title: "Device Settings" }} />
        <Stack.Screen name="ChangeSettingsScreenClimatePal" component={ChangeSettingsScreenClimatePal} options={{ title: "Device Settings" }} />
        <Stack.Screen name="ScheduleEventScreen" component={ScheduleEventScreen} options={{ title: "Schedule Event" }} />
        {/*
        <Stack.Screen name="TriggerResetScreen" component={TriggerResetScreen} /> */}
    </Stack.Navigator>
);

export default AppNavigator;