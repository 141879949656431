import { Platform } from 'react-native';

let FileSystem = null;
let Sharing = null;
let DocumentPicker = null;

if (Platform.OS !== 'windows' && Platform.OS !== 'macos') {
  FileSystem = require('expo-file-system');
  Sharing = require('expo-sharing');
  DocumentPicker = require('expo-document-picker');
}

export { FileSystem, Sharing, DocumentPicker };